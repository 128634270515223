import ButtonGroup from "core/components/ButtonGroup"
import { STR_FAILURE, STR_PAST_SESSIONS, STR_SUCCESS, STR_UPCOMING_SESSIONS } from "core/constants/strings"
import { Auth } from "domain/model/Auth"

import DashboardLayout from "core/layouts/DashboardLayout"
import useMenteeDetailsPageViewModel from "./MyMenteeDetialsPageViewModel"
import React from "react"
import UpcomingSessionTable from "./components/UpcomingSessionTable"
import PastSessionTable from "./components/PastSessionTable"
import MyMenteeCard from "./components/MyMenteeCard"
import MentorSessionTable from "../../Student/Mentor/components/MentorSessionTable"
import useMentorViewModel from "../../Student/Mentor/MentorViewModel"
import { useAuth } from "core/context/auth"
import { GetMentorSessionsByStudentMentor } from "domain/useCase/Mentor/MyMentee/GetMentorSessionsByStudentMentor"
import { MyMenteeRepositoryImpl } from "data/repository/Mentor/MyMenteeRepositoryImpl"
import MyMenteeAPIDataSourceImpl from "data/API/Mentor/MyMenteeAPIDataSourceImpl"
import { isEmpty } from "core/utils/misc"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material"
import Button from "core/components/new/Button"
import Menu from "assets/svgs/Menu"
import Model from "core/components/Model"
import { MarkMentorAbsent } from "domain/useCase/Student/Mentor/MarkMentorAbsent"
import { MentorRepositoryImpl } from "data/repository/Student/MentorRepositoryImpl"
import { MentorAPIDataSourceImpl } from "data/API/Student/MentorAPIDataSourceImpl"
import MentorModal from "presentation/Student/Mentor/components/MentorModal"
import { AddMentorFeedbackNew } from "domain/useCase/Mentor/MyMentee/AddMentorFeedbackNew"
import { MarkMenteeAbsent } from "domain/useCase/Mentor/MyMentee/MarkMenteeAbsent"
import { CancelMentorSession } from "domain/useCase/Student/Mentor/CancelMentorSession"
import { CancelMentorSessionByMentor } from "domain/useCase/Mentor/MyMentee/CancelMentorSessionByMentor"
import ConfirmationPopup from "./components/ConfirmationPopup"
import { GetMentorSessionDetails } from "domain/useCase/Mentor/MyMentee/GetMentorSessionDetails"

import Toast from "core/components/Toast"
import webEngageTrack from "core/utils/webEngageTrack"

export default function MenteeDetailsPage() {
  const {
    toast,
    activeButtonType,
    mentorSessionData,
    BATCHES_TO_IGNORE_NMF,
    upcomingSessionsTableHeaders,
    tableContent,
    changeToastVisibility,
    changeActiveButtonType,
    getMenteeMentorSessions,
    handleFinishedMentorSessions,
    addMentorFeedbacks,
    changeToastDetails,
  } = useMenteeDetailsPageViewModel()
  const { getMentorNameFromId, realMentor } = useMentorViewModel()
  const [isMenuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [lastCompletedSessionId, setLastCompletedSessionId] = React.useState("")
  const [isBookingAvailable, setIsBookingAvailable] = React.useState(false)
  const [newMentorshipFlow, setNewMentorshipFlow] = React.useState(false)
  const [menuActiveRow, setMenuActiveRow] = React.useState<number>(-1)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [agenda, setAgenda] = React.useState("")
  const [feedbackIndex, setFeedbackIndex] = React.useState<number>(-1)
  const [indexToBeMarkedAbsent, setIndexToBeMarkedAbsent] = React.useState("")
  const [cancelSessionIndex, setCancelSessionIndex] = React.useState<number>(-1)
  const toggleModal = (item?: any) => {
    setIsModalOpen(!isModalOpen)
    setAgenda(item.agenda)
  }
  const markMentorAbsentUseCase = new MarkMentorAbsent(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))

  const getMentorSessionDetailsUseCase = new GetMentorSessionDetails(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const addMentorFeedbackNewUseCase = new AddMentorFeedbackNew(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const getMentorSessionsByStudentMentorUseCase = new GetMentorSessionsByStudentMentor(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const markMenteeAbsentUseCase = new MarkMenteeAbsent(new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl()))
  const cancelMentorSessionByMentorUseCase = new CancelMentorSessionByMentor(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  function isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
  }
  async function handleGetMentorSessionDetails(data: any) {
    const response = await getMentorSessionDetailsUseCase.invoke(auth, data)
    if (response?.data?.id === lastCompletedSessionId) {
      setIsBookingAvailable(!isEmptyObject(response?.data?.mentor_feedback))
    }
  }
  async function addMentorFeedback(auth: Auth, rating: any, feedback: any, action_items: any, id: any) {
    const response = await addMentorFeedbackNewUseCase.invoke(auth, rating, feedback, action_items, id)
  }

  async function handleMarkMentorAbsent(data: any) {
    const response = await markMentorAbsentUseCase.invoke(auth, data)
    if (response?.success) {
    }
  }
  async function handleSubmit() {
    if (rating === 0 || comment === "" || feedbackIndex === -1 || actionItems === "") return

    await addMentorFeedback(
      auth,
      rating,
      comment,
      actionItems,

      items?.[feedbackIndex]?.id
    )

    closeSelectedFeedbackDialog("feedback")
    window.location.reload()
  }
  const [rating, setRating] = React.useState<number>(0)
  const [reviewIndex, setReviewIndex] = React.useState<number>(-1)
  const [confirmSessionIndex, setConfirmSessionIndex] = React.useState<number>(-1)
  const [comment, setComment] = React.useState<string>("")
  const [actionItems, setActionItems] = React.useState<string>("")
  const [isCancelOpen, setIsCancelOpen] = React.useState(false)

  function closeSelectedFeedbackDialog(type: string) {
    if (type === "review") setReviewIndex(-1)
    else if (type === "feedback") setFeedbackIndex(-1)
    else if (type === "cancel") setCancelSessionIndex(-1)
    else if (type === "reschedule") setRescheduleSessionIndex(-1)
    else if (type === "confirm") setConfirmSessionIndex(-1)
    else setFeedbackIndex(-1)
    setRating(0)
    setComment("")
    setActionItems("")
  }
  const handleCancelSession = (index: number) => {
    setCancelSessionIndex(index)
  }
  const [tableDataNew, setTableDataNew] = React.useState<any>({
    header: [],
    rows: [],
  })

  function formatDate(inputDate: any) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    if (!dateRegex.test(inputDate)) return inputDate

    const dateObject = new Date(inputDate)

    const dayOfMonth = dateObject.getDate()
    const monthName = dateObject.toLocaleString("default", { month: "short" })

    const getOrdinal = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"]
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }

    const dayWithOrdinal = getOrdinal(dayOfMonth)

    const formattedDate = `${dayWithOrdinal} ${monthName}`

    return formattedDate
  }
  function getStatusColorClass(status: any) {
    switch (status) {
      case "Pending":
        return "text-yellow-500"
      case "Not Booked":
        return "text-#ADADAD"

      case "not_booked":
        return "text-#ADADAD"
      case "Completed":
        return "text-green-500"
      case "":
        return "text-#ADADAD"
      case "Booked":
        return "text-yellow-500"
      default:
        return "text-red-500"
    }
  }

  async function cancelMentorSessionByMentor(session_id: any, type: any, student_id: any) {
    const response = await cancelMentorSessionByMentorUseCase.invoke(auth, session_id, type, student_id, "")
    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Mentor Session Cancelled Successfully.")
      changeToastVisibility(true)
    } else {
      changeToastDetails(STR_FAILURE, "Error Cancelling Session")
      changeToastVisibility(true)
    }
  }

  async function markMenteeAbsent(data: any) {
    const response = await markMenteeAbsentUseCase.invoke(auth, data)
    setItems(response.data)
  }
  async function getMentorSessionsByStudent(data: any) {
    const response = await getMentorSessionsByStudentMentorUseCase.invoke(auth, data)
    setItems(response.data)
  }
  const [rescheduleSessionIndex, setRescheduleSessionIndex] = React.useState<number>(-1)
  const handleRescheduleSession = (index: number) => {
    setRescheduleSessionIndex(index)
  }
  const [idToBeCancelled, setIdToBeCancelled] = React.useState("")
  const [emailToBeCancelled, setEmailToBeCancelled] = React.useState("")

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#414141",
      color: "#FFFFFF",
      boxShadow: theme.shadows[1],
      fontSize: "10px",
      maxWidth: "184px",
      textWrap: "wrap",
      padding: "8px 8px 8px 8px",
    },
  }))
  const { auth, refreshed } = useAuth()
  const [items, setItems] = React.useState<any>([])

  React.useEffect(() => {
    const itemsArray = Array.isArray(items) ? items : []
    const mentorSessionsList: any[] = itemsArray.map((item: any, i: any) => {
      const n = items.length

      const currentDate = new Date()
      const isDateRangeDefined = item?.startDate && item?.endDate
      const isPaymentDone = auth?.payment_status !== "NOT_PAID"

      const isBookNowEnabled =
        item?.session_status === "not_booked" &&
        item?.startDate &&
        item?.endDate &&
        new Date(item.startDate) <= currentDate &&
        currentDate <= new Date(item.endDate)

      const sessionStartTime: string = item?.session_start_time || ""
      const sessionEndTime: string = item?.session_end_time || ""
      // const sessionStartTimeFormatted: string = sessionStartTime.length < 2 ? `0${sessionStartTime}` : sessionStartTime
      // const sessionEndTimeFormatted: string = sessionEndTime.length < 2 ? `0${sessionEndTime}` : sessionEndTime
      function formatFractionalTime(hour: string | number): string {
        const hour24 = parseFloat(hour.toString()) // Ensure it's a number
        const hours = Math.floor(hour24) // Extract the integer part (hours)
        const minutes = Math.round((hour24 % 1) * 60) // Convert the fractional part to minutes
        const formattedHours = hours.toString().padStart(2, "0") // Ensure two digits for hours
        const formattedMinutes = minutes.toString().padStart(2, "0") // Ensure two digits for minutes
        return `${formattedHours}:${formattedMinutes}` // Return time in "HH:MM" format
      }
      const sessionStartTimeFormatted: string = formatFractionalTime(sessionStartTime)
      const sessionEndTimeFormatted: string = formatFractionalTime(sessionEndTime)
      const sessionDateTime: Date = new Date(`${item?.session_date}T${sessionStartTimeFormatted}`)
      const sessionEndDateTime: Date = new Date(`${item?.session_date}T${sessionEndTimeFormatted}`)
      const sessionDateStr = item.session_date
      const sessionStartTimeStr = item.session_start_time
      const sessionDate = new Date(sessionDateStr)
      const sessionStartTimeNew = parseInt(sessionStartTimeStr)

      sessionDate.setHours(sessionStartTimeNew, 0, 0, 0)
      const sessionStartTimePlus15 = new Date(sessionDate.getTime() + 15 * 60000)

      const now = new Date()

      const isTimeGreaterThan15Mins = now > sessionStartTimePlus15
      const isMarkMenteeAbsentEnabled = item?.session_status === "Booked" && isTimeGreaterThan15Mins

      const handleClick = () => {
        setIsOpen(!isOpen)
        setMenuOpen(!isMenuOpen)
        setIndexToBeMarkedAbsent(item?.id)
      }
      const timeDifference: number = sessionDateTime.getTime() - currentDate.getTime()
      const timeDifferenceEndTime: number = currentDate.getTime() - sessionEndDateTime.getTime()
      const isGreaterThan24Hours: boolean = timeDifference > 24 * 60 * 60 * 1000
      const thirtyMinutesInMillis = 30 * 60 * 1000
      const joinStartTime = sessionDateTime.getTime() - thirtyMinutesInMillis
      const joinEndTime = sessionEndDateTime.getTime() + thirtyMinutesInMillis
      const isGreaterThan0Secs = timeDifference > 0
      const isRescheduleEnabled: boolean = isGreaterThan0Secs && item?.session_status === "Booked"

      const isReviewOfLastSessionGiven = true
      // isBookingAvailable || item?.session_id === "msid1" || lastCompletedSessionId === ""
      const currTimeGreaterThanSessionStartTime =
        item?.startDate && new Date() <= new Date(new Date(item.startDate).getTime() - 15 * 24 * 60 * 60 * 1000)
      function formatTime(hour24: any) {
        // Convert the input to an integer in case it's provided as a string
        hour24 = parseInt(hour24, 10)

        // Determine AM or PM
        const period = hour24 < 12 ? "AM" : "PM"

        // Convert 24-hour time to 12-hour time
        let hour12 = hour24 % 12
        hour12 = hour12 === 0 ? 12 : hour12 // Convert "0" hour to "12" in the 12-hour format

        // Return the formatted time with ":00"
        return `${hour12}:00 ${period}`
      }

      const isJoinNowEnabled: boolean =
        currentDate.getTime() >= joinStartTime &&
        joinEndTime >= currentDate.getTime() &&
        (item?.session_status === "Booked" ||
          item?.status === "Booked" ||
          item?.session_status === "Completed" ||
          item?.status === "Completed") &&
        isReviewOfLastSessionGiven

      const isMentorReportVisible: boolean =
        item?.session_status === "Completed" && item?.student_review !== undefined && !isEmpty(item?.student_review)

      const hasMentorGivenFeedback: boolean = item?.mentor_feedback !== undefined && item?.mentor_feedback !== ""

      if (item?.session_status === "Completed") {
        setLastCompletedSessionId(item?.id)
      }

      return [
        "# " + 0 + (i + 1),
        item?.name,
        <Button
          outlined
          className="border-primary-color-bp bg-bg-White ml-auto w-fit border border-solid text-new-accent"
          onClick={() => toggleModal(item)}
        >
          View
        </Button>,
        item?.session_date && item?.session_start_time ? (
          <p
            className={
              item?.session_status != "not_booked" &&
              item?.session_status != "Completed" &&
              item?.session_status != "Booked"
                ? "text-red-500"
                : item?.status === "Completed"
                  ? ""
                  : "text-green-500"
            }
          >
            {formatDate(item?.session_date)} | {formatTime(item?.session_start_time)}
          </p>
        ) : item?.startDate?.charAt(0) !== "2" ? (
          "To be notified"
        ) : isBookNowEnabled ? (
          <p className="text-green-500">{formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}</p>
        ) : (
          <p>{formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}</p>
        ),
        <LightTooltip
          title={
            isJoinNowEnabled
              ? "Join Now"
              : item?.session_status === "not_booked"
                ? "Not Booked"
                : item?.session_status !== "Completed" && item?.session_status !== "Booked"
                  ? item?.session_status
                  : item?.session_status === "Completed"
                    ? "Your session has already been completed"
                    : "The link will be active 30 mins before the join time."
          }
          placement="bottom"
        >
          <span>
            <Button
              outlined
              className="border-primary-color-bp bg-bg-White ml-auto w-fit border border-solid"
              onClick={() => {
                webEngageTrack("Join now Mentor session", "mentor name")
                window.location.href = item?.meet_link_teacher
              }}
              disabled={!isJoinNowEnabled}
            >
              Join Now
            </Button>
          </span>
        </LightTooltip>,

        item?.mentor_name || "Not Booked",
        item?.session_status === "Completed" ? item?.duration + " mins" : "N/A",
        <p
          className={getStatusColorClass(
            new Date(item?.endDate) < new Date()
              ? "Expired"
              : currTimeGreaterThanSessionStartTime
                ? "Locked"
                : item?.session_status === "not_booked"
                  ? "Not Booked"
                  : item?.session_status || "N/A"
          )}
        >
          {new Date(item?.endDate) < new Date()
            ? "Expired"
            : currTimeGreaterThanSessionStartTime
              ? "Locked"
              : item?.session_status === "not_booked"
                ? "Not Booked"
                : item?.session_status || "N/A"}
        </p>,

        <Button
          outlined
          disabled={item?.session_status !== "Completed"}
          className="w-fit border-none bg-transparent text-new-accent underline hover:bg-transparent hover:text-new-accent"
          onClick={() => setReviewIndex(i)}
        >
          Click to view
        </Button>,
        <LightTooltip
          title={
            isMentorReportVisible && hasMentorGivenFeedback
              ? "View Mentor Report"
              : hasMentorGivenFeedback
                ? "Student Review will be available only after you submit your feedback"
                : "Mentor has not provided any feedback yet."
          }
        >
          <span>
            <Button
              outlined
              // disabled={!isMentorReportVisible || !hasMentorGivenFeedback}
              className="w-fit border-none bg-transparent text-new-accent underline hover:bg-transparent hover:text-new-accent"
              onClick={() => setFeedbackIndex(i)}
            >
              Click Here
            </Button>
          </span>
        </LightTooltip>,

        <div className="relative flex items-center">
          <button
            onClick={(event) => {
              setMenuOpen((isMenuOpen) => !isMenuOpen)
              setMenuActiveRow(i)
            }}
            className="relative text-white focus:outline-none"
          >
            <Menu />
          </button>
          {/* hovering=414141 normal=ADADAD */}
          {isMenuOpen && menuActiveRow === i && (
            <div
              className={`absolute ${
                i === n - 1 ? "bottom-5" : ""
              } right-6 z-[101] flex flex-col items-start gap-2 rounded-sm bg-white px-6 py-4 text-sm shadow-[0px_3px_7px_rgba(0,_0,_0,_0.1)]`}
            >
              <div
                onClick={() => {
                  setMenuOpen((isMenuOpen) => !isMenuOpen)
                  setIsCancelOpen(!isCancelOpen)
                  setIdToBeCancelled(item?.id)
                  setEmailToBeCancelled(item?.student_id)
                  isRescheduleEnabled && handleCancelSession(i)
                }}
                style={{ pointerEvents: isRescheduleEnabled ? "auto" : "none" }}
              >
                <h4
                  className={` ${
                    isRescheduleEnabled ? "text-neutral-light hover:text-red-500" : " text-new-editor-dark-50"
                  } ${isRescheduleEnabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                  Cancel Session
                </h4>
              </div>

              <div
                onClick={
                  isMarkMenteeAbsentEnabled
                    ? () => {
                        setIsOpen(!isOpen)
                        setMenuOpen(!isMenuOpen)
                        setIndexToBeMarkedAbsent(item?.id)
                        // markMenteeAbsent(item?.id)
                      }
                    : undefined
                }
              >
                <h4
                  className={` ${
                    isMarkMenteeAbsentEnabled ? "!text-neutral-light hover:text-red-500" : " text-new-editor-dark-50"
                  } ${isMarkMenteeAbsentEnabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                  Mark Mentee absent
                </h4>
              </div>
              <div
                onClick={() => (window.location.href = "mailto:help@bosscoderacademy.com")}
                style={{ pointerEvents: "auto", cursor: "pointer" }}
              >
                <h4 className="text-neutral-light  hover:text-neutral-dark">More Help</h4>
              </div>
            </div>
          )}
        </div>,
      ]
    })
    setTableDataNew({
      header: [
        "No.",
        "Session Name",
        "Session Agenda",
        "Date",
        // "Book Session",
        "Join Link",
        "Mentor Name",
        "Duration",
        "Status",
        "Student Feedback",
        "Mentor Feedback",
        "",
      ],
      rows: mentorSessionsList || [],
    })
    handleGetMentorSessionDetails(lastCompletedSessionId)
  }, [items, isMenuOpen, isBookingAvailable])

  const email = window.location.pathname.split("/")[3]
  React.useEffect(() => {
    // setNewMentorshipFlow(false)
    getMenteeMentorSessions(email)
    getMenteeMentorSessions(email as string)
    handleFinishedMentorSessions()
  }, [])
  React.useEffect(() => {
    if (
      mentorSessionData?.student_info?.batch_start_date >= 20240701 &&
      !BATCHES_TO_IGNORE_NMF.includes(mentorSessionData?.student_info?.student_batch)
    ) {
      setNewMentorshipFlow(true)
    }
  }, [mentorSessionData])
  React.useEffect(() => {
    getMentorSessionsByStudent(email)
  }, [auth])
  React.useEffect(() => {
    if (rating >= 0 && rating <= 5) {
      setItems((prev: any) =>
        prev.map((item: any, index: number) =>
          index === feedbackIndex ? { ...item, mentor_feedback: { ...item.mentor_feedback, rating } } : item
        )
      )
    }
  }, [rating])
  const [isOpen, setIsOpen] = React.useState(false)
  return newMentorshipFlow ? (
    <>
      <DashboardLayout isLoading={!mentorSessionData || mentorSessionData?.length === 0}>
        {isOpen && (
          <ConfirmationPopup
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            // email={student?.email}
            updateStudentsMentor={() => markMenteeAbsent(indexToBeMarkedAbsent)}
            heading="Are you sure you want to mark mentee absent for this session?"
          />
        )}
        {isCancelOpen && (
          <ConfirmationPopup
            isOpen={isCancelOpen}
            setIsOpen={setIsCancelOpen}
            // email={student?.email}
            // cancelMentorSessionByMentor(item?.id,"cancel",email);

            updateStudentsMentor={() => cancelMentorSessionByMentor(idToBeCancelled, "cancel", emailToBeCancelled)}
            heading="Are you sure you want to cancel this session?"
          />
        )}

        <div className="p-6">
          <div className="pb-8">
            <MyMenteeCard
              isNewMentorshipFlow={newMentorshipFlow}
              menteeName={mentorSessionData.student_info?.student_name}
              menteebatch={mentorSessionData?.student_info?.student_batch}
              menteeProfilePic={mentorSessionData?.student_info?.student_profile_pic}
              menteeEmail={email}
              menteeLeetcodeCount={mentorSessionData?.student_info?.leetcode_count}
              menteePhone={mentorSessionData?.student_info?.student_phone}
              isButtonEnabled={false}
            />
          </div>
          {/* <div className="">
            <ButtonGroup
              buttons={[STR_UPCOMING_SESSIONS, STR_PAST_SESSIONS]}
              w
              active={activeButtonType}
              onChange={changeActiveButtonType}
            />
          </div> */}
          <div></div>
          <div>
            <div className="my-4">
              <MentorSessionTable
                data={tableDataNew}
                gradient={false}
                isMenuOpen={isMenuOpen}
                menuActiveRow={menuActiveRow}
              />
            </div>
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </DashboardLayout>
      <Model isOpen={isModalOpen} onClose={() => toggleModal()} width={"max-w-5xl"}>
        <div className="flex flex-col gap-4">
          <div className="text-new-accent">The agenda of the session is:</div>
          <div>{agenda}</div>
        </div>
      </Model>
      {reviewIndex !== -1 && (
        <MentorModal
          index={reviewIndex}
          sessions={items?.[reviewIndex]}
          onClose={() => closeSelectedFeedbackDialog("review")}
          rating={items?.[reviewIndex]?.student_review["rating"]}
          comment={items?.[reviewIndex]?.student_review["comment"]}
          setComment={setComment}
          // onSubmit={handleSubmit}
          openedFrom="mentor"
          type="review"
          newMentorshipFlow={newMentorshipFlow}
        />
      )}
      {feedbackIndex !== -1 && (
        <MentorModal
          index={feedbackIndex}
          sessions={items?.[feedbackIndex]}
          onClose={() => closeSelectedFeedbackDialog("feedback")}
          rating={items?.[feedbackIndex]?.mentor_feedback["rating"]}
          setRating={setRating}
          comment={comment}
          setComment={setComment}
          actionItems={actionItems}
          setActionItems={setActionItems}
          type="feedback"
          onSubmit={handleSubmit}
          newMentorshipFlow={newMentorshipFlow}
          // type="review"
        />
      )}
    </>
  ) : (
    <>
      <DashboardLayout isLoading={!mentorSessionData || mentorSessionData?.length === 0}>
        <div className="p-6">
          <div className="pb-8">
            <MyMenteeCard
              menteeName={mentorSessionData.student_info?.student_name}
              menteebatch={mentorSessionData?.student_info?.student_batch}
              menteeProfilePic={mentorSessionData?.student_info?.student_profile_pic}
              menteeEmail={email}
              menteeLeetcodeCount={mentorSessionData?.student_info?.leetcode_count}
              menteePhone={mentorSessionData?.student_info?.student_phone}
              isButtonEnabled={false}
            />
          </div>
          <div className="">
            <ButtonGroup
              buttons={[STR_UPCOMING_SESSIONS, STR_PAST_SESSIONS]}
              active={activeButtonType}
              onChange={changeActiveButtonType}
            />
          </div>
          <div></div>
          <div>
            {activeButtonType === 0 ? (
              <UpcomingSessionTable
                tableHeaders={upcomingSessionsTableHeaders}
                data={mentorSessionData?.response?.booked}
                batch={mentorSessionData?.student_info?.student_batch}
              />
            ) : (
              <PastSessionTable
                data={tableContent.data}
                header={tableContent.header}
                sessions={mentorSessionData?.response?.finished}
                addMentorFeedbacks={addMentorFeedbacks}
              />
            )}
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </DashboardLayout>
    </>
  )
}
