// studentplacementdashboard
import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import { cn } from "core/lib/utils"
import useCreateBatchViewModel from "presentation/Admin/PlacementCreateBatch/CreateBatchViewModel"
import usePlacementConstant from "presentation/Admin/PlacementDashboard/components/PlacementConstants"
import useDashboardViewModel from "presentation/Admin/PlacementDashboard/DashboardViewModel"
import { useEffect, useState } from "react"
import FilterSection from "./components/FilterSection"
import PlacementListView from "./components/PlacementListView"

export default function DashboardView() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [placementIdForPopup, setPlacementIdForPopup] = useState<string | null>(null)
  const TABS = ["All Students", "Selected", "Rejected"]

  const {
    toast,
    searchPlacement,
    filteredPlacementList,
    changeToastVisibility,
    handleSendEmail,
    filters,
    setFilters,
    handelFiltersChange,
    fetchAllContests,
    fetchAllPlacementContests,
    allContestsList,
    allPlacementContests,
    getStudentList,
    totalPagesCount,
    handleChangePageNumber,
  } = useDashboardViewModel()

  const togglePopupOpen = (placementId: string) => {
    setPlacementIdForPopup(placementIdForPopup === placementId ? null : placementId)
  }

  const { getBatches, selectBatch } = useCreateBatchViewModel()
  const { programs } = usePlacementConstant()

  useEffect(() => {
    // getNewPlacement()
    fetchAllPlacementContests()
    fetchAllContests()
    getStudentList()
    getBatches()
    console.log("DashboardViewUseEffect")
  }, [])

  useEffect(() => {
    getStudentList()
  }, [filters])
  return (
    <DashboardLayout>
      <Toast data={toast} onclick={() => changeToastVisibility(false)} />
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-1">
          <h2 className="text-new-accent">Placement Student Dashboard</h2>
          <p>Track and maintain placement data.</p>
        </div>

        <div className="flex flex-row gap-8">
          <div className="flex items-center justify-center">
            <select
              className="w-full rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              value={filters.queryCohortPlacementTest || ""}
              onChange={(e) => handelFiltersChange(e.target.value, "queryCohortPlacementTest")}
            >
              <option value="">Select Placement Test</option>
              {allPlacementContests.map((contest: { id: string; name: string }, index: number) => (
                <option key={contest.id} value={contest.name}>
                  {contest.name}
                </option>
              ))}
            </select>
          </div>

          <div className="w-fit flex flex-row items-start content-start gap-2">
            {TABS.map((tab, index) => (
              <button
                key={index}
                className={`text-sm py-[6px] px-4 border border-solid border-new-neutral rounded-sm ${
                  filters.currentTab === tab ? "bg-new-accent text-white" : ""
                }`}
                onClick={() => handelFiltersChange(tab, "currentTab")}
              >
                {tab}
              </button>
            ))}
          </div>

          <button
            className={`text-sm py-[6px] px-4 border border-solid border-[#00AB14] rounded-sm text-[#00AB14]`}
            onClick={() => handleSendEmail()}
            disabled
          >
            Send Email
          </button>
        </div>
        <hr />
        <FilterSection filters={filters} setFilters={setFilters} selectBatch={selectBatch} programs={programs} />
        <PlacementListView filteredPlacementList={filteredPlacementList} />
        <div className="flex gap-2 w-[inherit] overflow-x-auto">
          {[...Array(totalPagesCount)].map((_, i) => (
            <button
              key={i}
              className={cn(
                "border border-new-accent shrink-0 inline-flex items-center justify-center min-w-[16px] h-6 p-2 hover:bg-new-accent hover:text-new-solid-white",
                filters.pageIndex === i + 1 ? "bg-new-accent text-new-solid-white" : "text-new-accent"
              )}
              onClick={() => handleChangePageNumber(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </DashboardLayout>
  )
}
