import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import React from "react"
import GetLiveContests from "domain/useCase/User/Contest/GetLiveContests"
import GetInstantContests from "domain/useCase/User/Contest/GetInstantContests"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { STR_FAILURE } from "core/constants/strings"
import GetContestDetails from "domain/useCase/User/Contest/GetContestDetails"
import GetStudentPastContests from "domain/useCase/User/Contest/GetStudentPastContests"
import { useNavigate, useParams } from "react-router-dom"
// import IsStudentRegistered from "domain/useCase/User/Contest/IsStudentRegistered"
import IsStudentRegisteredExam from "domain/useCase/User/Contest/IsStudentRegisteredExam"
import HasStudentStartedContest from "domain/useCase/User/Contest/HasStudentStartedContest"
import RegisterContest from "domain/useCase/User/Contest/RegisterContest"
import RegisterExam from "domain/useCase/User/Contest/RegisterExam"
import UnregisterContest from "domain/useCase/User/Contest/UnregisterContest"
import UnregisterExam from "domain/useCase/User/Contest/UnregisterExam"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import { isEmpty } from "core/utils/misc"
import { useApp } from "core/context/app"
import GetStudentContestDetails from "domain/useCase/User/Contest/GetStudentContestDetails"
import GetExamDetails from "domain/useCase/Admin/Contest/GetExamDetails"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import GetStudentPastExams from "domain/useCase/User/Contest/GetStudentPastExams"

export default function ContestViewModel() {
  const { auth, refreshed } = useAuth()
  const { student } = useApp()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const navigate = useNavigate()

  const [liveContests, setLiveContests] = useLocalStorage<any>("live_contest", [])
  const [instantContests, setInstantContests] = useLocalStorage<any>("instant_contest", [])
  const [pastContests, setPastContests] = useLocalStorage<any>("past_contest", [])

  const [activeTab, setActiveTab] = React.useState<number>(0)

  const [openPopup, setOpenPopup] = React.useState<boolean>(false)
  const [popupData, setPopupData] = React.useState<any>({})
  const [popupDataLoading, setPopupDataLoading] = React.useState<boolean>(false)
  const [contest, setContest] = React.useState<any>({})
  const [registeredLiveContests, setRegisteredLiveContests] = React.useState<any>([])
  const [activePopupContestId, setActivePopupContestId] = React.useState<any>("")
  const [flowType, setFlowType] = React.useState<string>("")
  const getStudentPastContestsUseCase = new GetStudentPastContests(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const getStudentPastExamsUseCase = new GetStudentPastExams(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getInstantContestsUseCase = new GetInstantContests(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const getLiveContestsUseCase = new GetLiveContests(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const getContestDetailsUseCase = new GetContestDetails(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getExamDetailsUseCase = new GetExamDetails(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))

  const registerContestUseCase = new RegisterContest(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const registerExamUseCase = new RegisterExam(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const unregisterContestUseCase = new UnregisterContest(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const unregisterExamUseCase = new UnregisterExam(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  // const isStudentRegisteredUseCase = new IsStudentRegistered(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const isStudentRegisteredExamUseCase = new IsStudentRegisteredExam(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const hasStudentStartedContestUseCase = new HasStudentStartedContest(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const fetchStudentPastContests = async () => {
    const response = await getStudentPastExamsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unknown error occured"))
      changeToastVisibility(true)
      return
    }

    setPastContests(response?.data)
  }

  const fetchInstantContests = async () => {
    const response = await getInstantContestsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unknown error occured"))
      changeToastVisibility(true)
      return
    }

    setInstantContests(response?.data)
  }

  const fetchLiveContests = async () => {
    const response = await getLiveContestsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unknown error occured"))
      changeToastVisibility(true)
      return
    }

    handleRegisteredContests(response?.data?.upcoming)
    setLiveContests(response?.data)
  }

  function handleRegisteredContests(data: any) {
    const upcomingContests = data
    if (upcomingContests?.length > 0) {
      setRegisteredLiveContests(
        upcomingContests
          .filter((contest: any) => Boolean(contest?.is_registered))
          .sort((a: any, b: any) => {
            return a?.start_time - b?.start_time
          })
      )
    }
  }

  const fetchContestDetails = async (contest_id: string) => {
    return await getExamDetailsUseCase.invoke(auth, contest_id)
  }

  // const isStudentRegistered = async (contest_id: string) => {
  //   return await isStudentRegisteredUseCase.invoke(auth, contest_id)
  // }
  const isStudentRegisteredExam = async (exam_id: string) => {
    return await isStudentRegisteredExamUseCase.invoke(auth, exam_id)
  }
  const hasStudentStartedContest = async (contest_id: string) => {
    return await hasStudentStartedContestUseCase.invoke(auth, contest_id)
  }

  const handleRegisterContest = async (contest_id: string) => {
    return await registerContestUseCase.invoke(auth, contest_id)
  }
  const handleRegisterExam = async (exam_id: string) => {
    return await registerExamUseCase.invoke(auth, exam_id)
  }
  const handleUnregisterContest = async (contest_id: string) => {
    return await unregisterContestUseCase.invoke(auth, contest_id)
  }
  const handleUnregisterExam = async (exam_id: string) => {
    return await unregisterExamUseCase.invoke(auth, exam_id)
  }
  const handleChangeButtonType = (index: number) => {
    setActiveTab(index)
  }

  const handleRedirectToContest = (contest_id: string) => {
    navigate(contest_id)
  }

  const onOpenPopup = async (contest_id?: any) => {
    if (isEmpty(contest_id)) return

    setOpenPopup(true)
    setPopupDataLoading(true)

    const response = await fetchContestDetails(contest_id)
    setPopupDataLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unknown error occured"))
      changeToastVisibility(true)
      return
    }

    setPopupData(response?.data)
    setActivePopupContestId(contest_id)
  }

  const onClosePopup = () => {
    setOpenPopup(false)
  }

  const redirectToContest = (contest_id: string) => {
    navigate(`/contests/${contest_id}/editor`)
  }

  return {
    toast,
    activeTab,
    pastContests,
    liveContests,
    instantContests,
    openPopup,
    popupData,
    popupDataLoading,
    registeredLiveContests,
    activePopupContestId,
    refreshed,
    student,
    setActivePopupContestId,
    redirectToContest,
    onOpenPopup,
    onClosePopup,
    fetchContestDetails,
    handleChangeButtonType,
    handleRedirectToContest,
    handleRegisterContest,
    handleUnregisterContest,
    // isStudentRegistered,
    hasStudentStartedContest,
    changeToastVisibility,
    fetchLiveContests,
    fetchInstantContests,
    fetchStudentPastContests,
    handleRegisteredContests,
    navigate,
    handleRegisterExam,
    handleUnregisterExam,
    isStudentRegisteredExam,
  }
}
