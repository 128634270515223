import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"
import CourseDataSource from "data/dataSource/Student/CourseDataSource"

export class CourseRepositoryImpl implements CourseRepository {
  private dataSource: CourseDataSource

  constructor(dataSource: CourseDataSource) {
    this.dataSource = dataSource
  }

  async getCourses(auth: Auth) {
    return this.dataSource.getCourses(auth)
  }

  async getSessionDetail(auth: Auth, session_id: string) {
    return this.dataSource.getSessionDetails(auth, session_id)
  }

  async getFullCourseContent(auth: Auth, module_id: string) {
    return this.dataSource.getFullCourseContent(auth, module_id)
  }

  async markAttendance(auth: Auth, session_id: string) {
    return this.dataSource.markAttendance(auth, session_id)
  }
  async reedemCoin(auth: Auth, data: any) {
    return this.dataSource.reedemCoin(auth, data)
  }

  async fetchLeetCodeDetails(auth: Auth) {
    return this.dataSource.fetchLeetCodeDetails(auth)
  }

  async setLeetCodeId(auth: Auth, leetcodeId: string) {
    return this.dataSource.setLeetCodeId(auth, leetcodeId)
  }

  async toggleRecordedComplete(auth: Auth, session_id: string, status: boolean) {
    return this.dataSource.toggleRecordedComplete(auth, session_id, status)
  }

  async toggleLeetCodeProblemComplete(auth: Auth, problem_id: string, status: boolean, coins: number) {
    return this.dataSource.toggleLeetCodeProblemComplete(auth, problem_id, status, coins)
  }

  async togglePlatformProblemStatus(auth: Auth, problem_id: string, status: boolean) {
    return this.dataSource.togglePlatformProblemStatus(auth, problem_id, status)
  }

  async getFeedbackDetails(auth: Auth, class_id: string) {
    return this.dataSource.getFeedbackDetails(auth, class_id)
  }

  async submitFeedback(auth: Auth, data: any) {
    return this.dataSource.submitFeedback(auth, data)
  }

  async submitRecordingFeedback(auth: Auth, data: any) {
    return this.dataSource.submitRecordingFeedback(auth, data)
  }

  async getMasterClass(auth: Auth) {
    return this.dataSource.getMasterClass(auth)
  }

  async getAttendanceAndAssignments(auth: Auth): Promise<any> {
    return this.dataSource.getAttendanceAndAssignments(auth)
  }

  async markAttendanceManually(auth: Auth, session_id: string, status: boolean) {
    return this.dataSource.markAttendanceManually(auth, session_id, status)
  }

  async getSSMFeedbackResponse(auth: Auth, session_id: string, questionName: string, questionType: string) {
    return this.dataSource.getSSMFeedbackResponse(auth, session_id, questionName, questionType)
  }

  async getDsmlBeginnerModuleSessions(auth: Auth) {
    return this.dataSource.getDsmlBeginnerModuleSessions(auth)
  }
}
