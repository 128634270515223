import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useContestApproveViewModel from "./AIFeedbackViewModal"
import { useEffect } from "react"
import Toast from "core/components/Toast"
import Spinner from "core/components/Spinner"
import ApproveTable from "./components/ApproveTable"
import { cn } from "core/lib/utils"
import Button from "core/components/Button"

export default function AIFeedbackView() {
  const {
    tableHeaders,
    changeToastVisibility,
    handleSearchByEmail,
    nextStudentsLoading,
    searchByEmail,
    toast,
    refreshed,
    emailInputRef,
    studentFeedbacks,
    getTop100Feedbacks,
    getFeedbackByEmail,
    handleCopy,
    pageNumber,
    setPagenumber,
    numberOfPages,
    setNumberOfPages,
  } = useContestApproveViewModel()
  const numberOfPagesOptions = [10]
  const array = Array.from({ length: numberOfPages }, (_, index) => index + 1)
  useEffect(() => {
    console.log("page number:", pageNumber)
    getTop100Feedbacks(pageNumber)
  }, [pageNumber])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">AI Feedback</h2>
          <p>AI Feedback for Subjective Assignment Questions</p>
        </div>

        <div className="flex w-full flex-wrap gap-4">
          <Input
            id="search-email"
            ref={emailInputRef}
            type="email"
            placeholder="Search with email"
            value={searchByEmail}
            onChange={handleSearchByEmail}
            containerClass="w-60"
            search
          />
          <Button className="w-1/6 h-9" onClick={() => getFeedbackByEmail(searchByEmail)}>
            Search
          </Button>
          {nextStudentsLoading && (
            <div className="flex items-center">
              <Spinner small={true} />
            </div>
          )}
        </div>

        <ApproveTable
          tableHeaders={tableHeaders}
          studentFeedbacks={studentFeedbacks}
          handleCopy={handleCopy}
          searchByEmail={searchByEmail}
          loading={nextStudentsLoading}
        />
        {!searchByEmail && (
          <div className="custom-scrollbar relative mx-auto mb-[50px] flex max-w-5xl overflow-x-scroll">
            <div className="flex transform gap-2">
              {array.map((num: any, i: number) => (
                <div key={i} className="">
                  <button
                    className={cn("h-10 w-10 border", pageNumber === i && "bg-[#312E81] text-white")}
                    onClick={() => {
                      setPagenumber(i)
                    }}
                  >
                    {i + 1}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
