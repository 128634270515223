// import DashboardLayout from "core/layouts/DashboardLayout"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import useStoreViewModel from "./StoreViewModel"
import Toast from "core/components/Toast"
import { ChevronDownIcon, CoinIcon, CrossIcon } from "core/constants/svgs"
import StandaredSelect from "core/components/StandaredSelect"
import amazon_vocher from "assets/images/amazon_voucher.png"
import { Tooltip } from "@mui/material"
import extra_mentor_session from "assets/images/extra_mentor_session.png"
import ConfirmCouponModal from "./components/ConfirmCouponModal"
import ConfirmMentorSessionModal from "./components/ConfirmMentorSessionModal"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "core/components/v2/ui/dropdown-menu"
import FreezeActive from "assets/svgs/FreezeActive"
import { useEffect, useState } from "react"
import OrderHistory from "./components/OrderHistory"
import PointIcon from "assets/svgs/PointIcon"
import { Button } from "core/components/v2/Button"

export default function StoreView() {
  const {
    toast,
    student,
    orderHistory,
    activeButtonType,
    allMentorCoupons,
    fetchOrderHistory,
    FetchMentorSessionCoupon,
    changeActiveButtonType,
    allCoupons,
    amount,
    redeemModal,
    amountToCoinsMap,
    mentorSessionRedeemModal,
    redeemCouponLoader,
    mentorCouponLoader,
    RedeemMentorCoupon,
    RedeemCoupon,
    handleMentorSessionModal,
    handleRedeemCoinsModal,
    handleAmountChange,
    changeToastVisibility,
    changeToastDetails,
    activeAmount,
    setActiveAmount,
  } = useStoreViewModel()

  return (
    <DashboardLayoutv2>
      <div className="px-[24px] pt-[24px]">
        <div className="space-y-6 text-new-neutral-dark">
          <div>
            <p className="text-[#333] text-[16px] font-[600]">Redeem Coins</p>
            <p className="text-[#646464] font-[400] text-[14px]">
              Redeem coins for Amazon Gift Cards or an additional Mentor Session.
            </p>
          </div>
          <div className="">
            <div className="flex gap-x-[32px] justify-between">
              <div className="flex h-[324px] w-1/2 flex-col overflow-hidden rounded-md  border-[1px] border-[#DEDEDE] shadow-[-1px_1px_5px_rgba(34,41,48,0.15)]">
                <div className="h-[181px] w-full px-[16px] pt-[16px] ">
                  <img className="h-full w-full rounded-[8px] " src={amazon_vocher} alt="" />
                </div>
                <div className="flex flex-col gap-y-[15px] p-[16px] pt-[8px]">
                  <div className="flex items-center justify-between">
                    <div className="flex w-full justify-between items-center">
                      <h4 className="text-[14px] font-[600]">Amazon Gift Card</h4>

                      <div className="flex items-center gap-2">
                        {activeAmount === 0 && (
                          <>
                            <span>
                              <PointIcon className="h-6 w-6" />
                            </span>
                            <div className="px-[8px] py-[4px] bg-[#FEFFE4]">
                              <h3 className="text-[#FBBF24] text-[14px] font-[600]">{amountToCoinsMap[amount]}</h3>
                            </div>
                          </>
                        )}
                        {activeAmount === 1 && (
                          <>
                            <span>
                              <FreezeActive className="h-6 w-6" />
                            </span>
                            <div className="px-[8px] py-[4px] bg-[#EEF8FD]">
                              <h3 className="text-[#1C3474] text-[14px] font-[600]">
                                {amount ? parseInt(amount.match(/\d+/)?.[0] || "0", 10) : "N/A"}
                              </h3>
                            </div>
                          </>
                        )}

                        <DropdownMenu>
                          <DropdownMenuTrigger>
                            <div className="flex items-center gap-[4px]">
                              {activeAmount === -1 && <p className="text-[14px] font-[400]">Coin Type</p>}
                              <button className="hover:bg-[#ebebeb] rounded-[2px] flex items-center justify-center p-[4px]">
                                <ChevronDownIcon className="size-[16px]" />
                              </button>
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="bg-white w-[200px]">
                            <DropdownMenuItem>
                              <button
                                onClick={() => setActiveAmount(0)}
                                className="flex items-center gap-[8px] p-[4px] hover:bg-slate-100 py-2 w-full rounded-sm"
                              >
                                <PointIcon className="size-[16px]" />
                                <span className="text-[14px] font-[400]">Bosscoder Coins</span>
                              </button>
                            </DropdownMenuItem>
                            <DropdownMenuItem>
                              <button
                                onClick={() => setActiveAmount(1)}
                                className="flex items-center gap-[8px] p-[4px] hover:bg-slate-100 py-2 w-full rounded-sm"
                              >
                                <FreezeActive className="size-[16px]" />
                                <span className="text-[14px] font-[400]">Cash Rewards</span>
                              </button>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-[35px]">
                    <div className="">
                      <StandaredSelect
                        required
                        name="Amount"
                        id="Amount"
                        options={activeAmount !== -1 ? allCoupons : []}
                        placeholder={activeAmount === -1 ? "Select Coin" : "Select Amount"}
                        onChange={handleAmountChange}
                        value={amount}
                        className="h-[35px] w-[150px] border-[#D3D3D3] border-[1px] rounded-[4px]"
                      />
                    </div>
                    <div className="">
                      {amount == "" && (
                        <Tooltip title="Select Amount">
                          <span className="">
                            {" "}
                            <Button
                              variant={"primary"}
                              disabled={amount == "" ? true : false}
                              onClick={handleRedeemCoinsModal}
                              className="h-[35px] w-[87px]"
                            >
                              Redeem
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      {amount != "" && (
                        <Button
                          variant={"primary"}
                          disabled={amount == "" ? true : false}
                          onClick={handleRedeemCoinsModal}
                          className="h-[35px] w-[87px]"
                        >
                          Redeem
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-[324px] w-1/2 flex-col overflow-hidden  rounded-md border-[1px]  border-[#DEDEDE] shadow-[-1px_1px_5px_rgba(34,41,48,0.15)]">
                <div className="h-[181px] w-full px-[16px] pt-[16px] ">
                  <img className="h-full w-full rounded-[8px] " src={extra_mentor_session} alt="" />
                </div>
                <div className="flex flex-col gap-y-[15px] p-[16px] pt-[8px]">
                  <div className="flex items-center justify-between">
                    <h4 className="text-[14px] font-[600]">Additional Mentor Session</h4>
                    <div className="flex items-center gap-2">
                      <span>
                        <PointIcon className="h-6 w-6" />
                      </span>
                      <div className="px-[8px] py-[4px] bg-[#FEFFE4]">
                        <h3 className="text-[#FBBF24] text-[14px] font-[600]">100000</h3>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-[35px]">
                    <div className="">
                      <div className="">
                        {student?.points < 100000 && (
                          <Tooltip title="You do not have sufficient coins">
                            <span className="">
                              <Button
                                variant={"primary"}
                                disabled={student?.points < 100000 ? true : false}
                                onClick={handleMentorSessionModal}
                                className="h-[33px]"
                              >
                                Book Mentor Session
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                        {student?.points >= 100000 && (
                          <Button variant={"primary"} onClick={handleMentorSessionModal} className="h-[33px]">
                            Book Mentor Session
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {redeemModal && (
                <ConfirmCouponModal
                  handleRedeemCoinsModal={handleRedeemCoinsModal}
                  amount={parseInt(amount.match(/\d+/)?.[0] || "0", 10)}
                  activeAmount={activeAmount}
                  coins={amountToCoinsMap[amount]}
                  RedeemCoupon={RedeemCoupon}
                  loader={redeemCouponLoader}
                />
              )}
              {mentorSessionRedeemModal && (
                <ConfirmMentorSessionModal
                  handleMentorSessionModal={handleMentorSessionModal}
                  RedeemMentorCoupon={RedeemMentorCoupon}
                  loader={mentorCouponLoader}
                />
              )}
            </div>

            <OrderHistory
              changeToastVisibility={changeToastVisibility}
              changeToastDetails={changeToastDetails}
              orderHistory={orderHistory}
              activeButtonType={activeButtonType}
              allMentorCoupons={allMentorCoupons}
              fetchOrderHistory={fetchOrderHistory}
              FetchMentorSessionCoupon={FetchMentorSessionCoupon}
              changeActiveButtonType={changeActiveButtonType}
            />
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
