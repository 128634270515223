import ConditionalLoader from "core/components/ConditionalLoader"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import Toast from "core/components/Toast"
import { STR_TEXT_FIELD } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import DashboardLayout from "core/layouts/DashboardLayout"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import React from "react"
import useCreateBatchViewModel from "./CreateBatchViewModel"

export default function CreateBatchView() {
  const {
    toast,
    selectBatch,
    _currentSelectBatch,
    _currentSelectContest,
    _placementBatchName,
    _bridgeBatchName,
    loading,
    refreshed,
    _setCurrentSelectBatch,
    _setCurrentSelectContest,
    handlePlacementCourseName,
    handleBridgeCourseName,
    handleBatchChange,
    handleContestChange,
    changeToastVisibility,
    getBatches,
    fetchAllContests,
    allContestsList,
    createBatches,
  } = useCreateBatchViewModel()

  React.useEffect(() => {
    if (refreshed) {
      getBatches()
      fetchAllContests()
    }
  }, [refreshed])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">Create Placement Cohort</h2>
        </div>
        <div className="flex w-full max-w-[400px] flex-col gap-4">
          <Input
            type={STR_TEXT_FIELD}
            placeholder="Placement course name"
            onChange={handlePlacementCourseName}
            value={_placementBatchName}
            containerClass="w-full"
          />
          <Input
            type={STR_TEXT_FIELD}
            placeholder="Bridge course name"
            onChange={handleBridgeCourseName}
            value={_bridgeBatchName}
            containerClass="w-full"
          />

          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(_currentSelectBatch)}
              loader={<em className="text-xs text-new-neutral-light">No Batch Selected</em>}
            >
              {_currentSelectBatch.map((batch: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {batch}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => {
                      _setCurrentSelectBatch(removeAtIndex(_currentSelectBatch, i))
                    }}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <StandaredSelect
            required
            options={selectBatch}
            placeholder="Select Batches"
            onChange={handleBatchChange}
            value={_currentSelectBatch.length > 0 ? _currentSelectBatch[_currentSelectBatch.length - 1] : ""}
          />

          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(_currentSelectContest)}
              loader={<em className="text-xs text-new-neutral-light">No Selected Contest</em>}
            >
              {_currentSelectContest.map((contest: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {contest.name}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => {
                      _setCurrentSelectContest(removeAtIndex(_currentSelectContest, i))
                    }}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <StandaredSelect
            required
            options={allContestsList.map((contest: { id: string; name: string }) => contest.name)}
            placeholder="Select Contests"
            onChange={handleContestChange}
            value={_currentSelectContest.length > 0 ? _currentSelectContest[_currentSelectContest.length - 1].name : ""}
          />

          <Button disabled={loading} loading={loading} onClick={createBatches}>
            Create Placement Cohort
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
