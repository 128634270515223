import React, { useState } from "react"
import useContestViewModel from "../ViewModel"
import useToast from "core/hooks/useToast"
import useLocalStorage from "core/hooks/useLocalStorage"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useNavigate, useParams } from "react-router-dom"
import { genError } from "core/utils/string"
import { useApp } from "core/context/app"
import GetStudentContestDetails from "domain/useCase/User/Contest/GetStudentContestDetails"
import GetStudentExamDetails from "domain/useCase/User/Contest/GetStudentExamDetails"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import AssessmentContestRepositoryImpl from "data/repository/Student/ContestRepositoryImpl"
import AssessmentContestAPIDataSourceImpl from "data/API/Student/ContestAPIDataSourceImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import ReattemptAttemptedContest from "domain/useCase/User/Contest/ReattemptAttemptedContest"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import GetAllContest from "domain/useCase/Student/Assessment/GetAllContest"
import GetAllExams from "domain/useCase/Student/Assessment/GetAllExams"
export const placement_students = [
  // "jaydipdey2807@gmail.com",
  // "2000bhawesh@gmail.com",
  // "thalapalacharandurga@gmail.com",
  // "golukhandelwal2003@gmail.com",
  // "sinhakhushboo31@gmail.com",
  // "skanjumkhan09@gmail.com",
  // "appu6688kumar@gmail.com",
  // "goyalvaishali307@gmail.com",
  // "shubhrant100@gmail.com",
  // "kumarisomlatha@gmail.com",
  // "its.vighneshparadkar120@gmail.com",
  // "vighneshparadkar120@gmail.com ankushukey4@gmail.com",
  // "kedarvaidya22@gmail.com",
  // "kkseth412@gmail.com",
  // "goyalvaishali307@gmail.com",
  // "rajugurjar30@gmail.com",
  // "kdhananjay931@gmail.com",
  // "pkarthi21@iitk.ac.in",
  // "ravitanya8@gmail.com",
  "vaishnavigunge01@gmail.com",
  "ranjeet1594@gmail.com",
  "shivanshk85@gmail.com",
  "vamshikurlagunda@gmail.com",
  "priyankachougale121212@gmail.com",
  "rajchandwani64@gmail.com",
  "gautamnaskar82@gmail.com",
  "augustyat1990@gmail.com",
  "prabhatkumarmnnit@gmail.com",
  "aswinpkrish@gmail.com",
  "kamleshmind0130@gmail.com",
  "rajiivdubey@gmail.com",
  "pritipragnya25@gmail.com",
  "sanjeebmaharana1@gmail.com",
  "soniamann2011@gmail.com",
  "3012ektapatil@gmail.com",
  "noork50319@gmail.com",
  "medhabhat56@gmail.com",
  "korutla.r@northeastern.edu",
  "abhikhedkar25@gmail.com",
  "sanlapray2@gmail.com",
  "koushiksivan98@gmail.com",
  "ashwinipurohith56@gmail.com",
  "fhashmi1996@gmail.com",
  "jainsaiyam71@gmail.com",
  "ashutoshdas269@gmail.com",
  "jainridam08@gmail.com",
  "aishumadhu1997@gmail.com",
  "jerin.k.ashok1@gmail.com",
  "vamsijay11@gmail.com",
  "ketakidhande29@gmail.com",
  "roshanvaishnav02@gmail.com",
  "pooja.gori27@gmail.com",
  "bohra.nehaa@gmail.com",
  "raj8804816038@gmail.com",
  "shubhg1999@gmail.com",
  "tavvaalekhya2@gmail.com",
  "raghvendra0798@gmail.com",
  "darpan4010@gmail.com",
  "neethi5gms@gmail.com",
  "yathishats1999@gmail.com",
  "inamdarparvej@gmail.com",
  "raviteja5667@gmail.com",
  "skandtripathi1@gmail.com",
  "deepanshu.ver777@gmail.com",
  "kplagarwal8@gmail.com",
  "hiren.ramoliya367@gmail.com",
  "npbhole53@gmail.com",
  "harshigupta09@gmail.com",
  "kwadehra10@gmail.com",
  "pratapneupane@gmail.com",
  "divyadhaneesh@gmail.com",
  "mamillapallivinay@gmail.com",
  "sharyudhawas01@gmail.com",
  "sudhanandhi0611@gmail.com",
  "rose.bhagi@gmail.com",
  "rashiaqua924@gmail.com",
  "tiwaryashish97@gmail.com",
  "sameer881sameer@gmail.com",
  "vamshipodishetti@gmail.com",
  "rajatsingh2506@gmail.com",
  "sharmaamanjsr@gmail.com",
  "210bakshi@gmail.com",
  "viratvenom19@gmail.com",
  "singh.navdeep1312@gmail.com",
  "akash121277.av@gmail.com",
  "gauravgawade43@gmail.com",
  "satyamsharma9068@gmail.com",
  "karthik438@gmail.com",
  "tarungandhi1224@gmail.com",
  "udit.bhalla94@gmail.com",
  "Pavanpatil2392@gmail.com",
  "soundarrajanmcetj@gmail.com",
  "manhas.udhay@gmail.com",
  "Pranjal.taye@gmail.com",
  "anubhavsachdev1994@gmail.com",
  "raghulsonu@gmail.com",
  "tejasgautam2@gmail.com",
  "jit.dutta@aot.edu.in",
  "pramatheshts025@gmail.com",
  "royricheek@gmail.com",
  "Mondeep.5658@gmail.com",
  "Boggarapu.praneeth8@gmail.com",
  "dthakur8011@gmail.com",
  "rishabhrakesh26@gmail.com",
  "vignangummadi27@gmail.com",
  "vignangummadi27@gmail.com",
  "priyankatn09@gmail.com",
  "sakshi7799.agarwal@gmail.com",
  "Vaibhavsharma855893@gmail.com",
  "rajubitsmesra@rocketmail.com",
  "anushreesnd12@gmail.com",
  "shubhi93ec@gmail.com",
  "hrishiravi10@gmail.com",
  "saranya.pakalapati@gmail.com",
  "gjahnavi22@gmail.com",
  "ananyatripathi152@gmail.com",
  "prakharyadav708@gmail.com",
  "kanika.masiwal@gmail.com",
  "sharma.vasu52@gmail.com",
  "pandeysoumya22@gmail.com",
  "rashmip3000@gmail.com",
  "Pratiksha4066@gmail.com",
  "yashu.1031999@gmail.com",
  "Kuchhalarushi21@gmail.com",
  "sandeep.norrin@gmail.com",
  "penchalanihar@gmail.com",
  "rushil2409@gmail.com",
  "udaya.d08@gmail.com",
  "erharipriya2016@gmail.com",
  "Nikitasurya30@gmail.com",
  "pujagupta3455@gmail.com",
  "ada.raj96@gmail.com",
  "nelsonenterprizes@gmail.com",
  "mondalsagarkumar2@gmail.com",
  "gufrana.ferz@gmail.com",
  "GHimanshu2898@gmail.com",
  "upendrapedaprolu@gmail.com",
  "ngrsrikanth@gmail.com",
  "Mjvibhute@yahoo.com",
  "shramikaadmanesva@gmail.com",
  "mjb1707@gmail.com",
  "chilamakurvishnu@gmail.com",
  "svpattanaik@gmail.com",
  "manish.shirsikar@gmail.com",
  "meghanaias2022@gmail.com",
  "mshettydasharath@gmail.com",
  "rajatraut1234@gmail.com",
  "bothare23shree@gmail.com",
  "abhiramtv36@gmail.com",
  "dwivedikaustubh18@gmail.com",
  "bhaumeek.0419@gmail.com",
  "venkatgobi@gmail.com",
  "tamal.biswas110@gmail.com",
  "tatun594@gmail.com",
  "tejesh15797@gmail.com",
  "donglemonika7@gmail.com",
  "manjunathtelagadi@gmail.com",
  "saikirann.sas@gmail.com",
  "connectwithpratikgajbe@gmail.com",
  "abhinashpatnaik67@gmail.com",
  "vishwpatel99@gmail.com",
  "rahul.rajeevan4@gmail.com",
  "Shivam.11712711@gmail.com",
  "rajeevravi6710@gmail.com",
  "bhiwa.gem@gmail.com",
  "pkarthi21@iitk.ac.in",
  "ravitanya8@gmail.com",
  "pranjalijadkar01@gmail.com",
]

export default function ViewModel() {
  const { auth } = useAuth()
  const { contest_id } = useParams()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { student } = useApp()

  const navigate = useNavigate()

  const [contest, setContest] = useLocalStorage<any>(`contest-${contest_id}`, {})
  const [registering, setRegistering] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const [isApproved, setIsApproved] = useState(true)
  const [fetchedRegisteredStatus, setFetchedRegisteredStatus] = useState(0)
  const [enterContestDisabled, setEnterContestDisabled] = useState(true)
  const [loadingContest, setLoadingContest] = useState<boolean>(true)
  const [isPast, setIsPast] = useState<boolean>(false)
  const [reattempt, setReattempt] = React.useState<boolean>(false)
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false)
  const [secondAttempt, setSecondAttempt] = React.useState(false)
  const [live, setLive] = React.useState<boolean>(false)
  const {
    openPopup,
    refreshed,
    redirectToContest,
    onOpenPopup,
    onClosePopup,
    fetchContestDetails,
    handleRegisterContest,
    handleRegisterExam,
    isStudentRegisteredExam,
  } = useContestViewModel()

  const getStudentContestDetailsUseCase = new GetStudentContestDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const reattemptAttemptedContestUseCase = new ReattemptAttemptedContest(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const getStudentExamDetailsUseCase = new GetStudentExamDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const handleRegister = async () => {
    setRegistering(true)
    const response = await handleRegisterExam(contest_id as string)
    setRegistering(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Successfully registered for contest")
    changeToastVisibility(true)
  }

  const fetchContest = async () => {
    setLoadingContest(true)

    const response = await fetchContestDetails(contest_id as string)

    setLoadingContest(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    const contest = response?.data
    setContest(contest)

    const currentTimestamp = Date.now()
    const startTimestamp = parseInt(contest?.start_time) * 1000
    const duration = parseInt(contest?.duration) * 60
    const endTimestamp = startTimestamp + duration * 1000
    setIsPast(currentTimestamp > endTimestamp)
  }

  const fetchStudentContestDetails = async () => {
    const response = await getStudentExamDetailsUseCase.invoke(auth, contest_id as string)
    if (!response?.data?.flowType || response?.data?.flowType !== "newFlow") {
      if (response?.data?.submitted === true) {
        setSecondAttempt(true)
      }
    }
  }
  const reattemptAttemptedContest = async () => {
    await reattemptAttemptedContestUseCase.invoke(auth, contest_id as any)
  }

  const [page, setPage] = useState(1)
  const limit = 20

  const GetAllContestUseCase = new GetAllContest(
    new AssessmentContestRepositoryImpl(new AssessmentContestAPIDataSourceImpl())
  )
  const GetAllExamsUseCase = new GetAllExams(
    new AssessmentContestRepositoryImpl(new AssessmentContestAPIDataSourceImpl())
  )
  const [allContest, setAllContest] = useLocalStorage<any>("contest", {})

  const updateRegisteredStatus = async (exam_id: string) => {
    const response = await isStudentRegisteredExam(exam_id)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      setFetchedRegisteredStatus(-1)
      return
    }
    setIsRegistered(response?.data?.registered)
    setIsApproved(response?.data?.is_approved)
    setReattempt(response?.data?.attempted_live)
    setFetchedRegisteredStatus(1)
  }

  const checkContestStatus = () => {
    const currentTimestamp = Date.now()
    const duration = parseInt(contest?.duration) * 60 * 1000
    const startTimestamp = parseInt(contest?.start_time) * 1000
    const endTimestamp = startTimestamp + duration

    const isContestRunning = startTimestamp <= currentTimestamp && currentTimestamp <= endTimestamp

    const isLiveContest = contest?.type === "live"

    if (isLiveContest) {
      setEnterContestDisabled(!isContestRunning)
    } else {
      setEnterContestDisabled(!(currentTimestamp >= startTimestamp))
    }
  }

  const redirect = () => {
    redirectToContest(contest_id as string)
  }

  return {
    loadingContest,
    contest,
    openPopup,
    contest_id,
    isPast,
    isRegistered,
    isApproved,
    enterContestDisabled,
    fetchedRegisteredStatus,
    registering,
    toast,
    refreshed,
    student,
    reattempt,
    openConfirmationModal,
    secondAttempt,
    setSecondAttempt,
    setOpenConfirmationModal,
    live,
    onOpenPopup,
    handleRegister,
    redirect,
    onClosePopup,
    changeToastVisibility,
    checkContestStatus,
    fetchStudentContestDetails,
    fetchContest,
    navigate,
    reattemptAttemptedContest,
    page,
    limit,
    updateRegisteredStatus,
  }
}
