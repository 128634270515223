import React from "react"
import LeaderboardBG from "assets/svgs/LeaderboardBG"
import LeaderboardCard from "./components/LeaderboardCard"
import useLeaderboardViewModel from "./LeaderboardViewModel"
import Table from "./components/LeaderboardTable"
import { STR_LEADERBOARD, STR_LEADERBOARD_SUBTITLE } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import LeaderboardBG2 from "assets/svgs/LeaderBoardBG2"
import SilverMedel from "assets/svgs/SilverMedel"
import DownArrow from "assets/svgs/DownArrow"
import GoldMedal from "assets/svgs/GoldMedal"
import BronzeMedal from "assets/svgs/BronzeMedal"
import SilverFlip from "assets/svgs/SilverFlip"
import DownArrow1 from "assets/svgs/DownArrow1"
import GoldFlip from "assets/svgs/GoldFlip"
import BronzeFlip from "assets/svgs/BronzeFlip"
import DownArrow2 from "assets/svgs/DownArrow2"
import DownArrow3 from "assets/svgs/DownArrow3"
import Avatar from "react-avatar"
import Line from "core/components/payment-method/Line"
import LineBottom from "core/components/payment-method/LineBottom"
import { Head } from "core/components/seo"

export default function LeaderBoardView() {
  const { leaderboardList, tableData, refreshed, student, fetchLeaderboard, changeTableData } =
    useLeaderboardViewModel()

  React.useEffect(() => {
    if (refreshed && !isEmpty(student)) {
      fetchLeaderboard()
      console.log("i am leaderboard", leaderboardList)
    }
  }, [refreshed, student])

  React.useEffect(() => {
    changeTableData()
  }, [leaderboardList])

  return (
    <DashboardLayoutv2 isLoading={leaderboardList.length === 0}>
      <Head title="Leaderboard" />

      <div className="">
        <div className="relative overflow-hidden mx-[13px] mt-[16px]">
          <div className="absolute top-0 animate-moveWhite">
            <Line />
          </div>
          <div className="absolute top-0 animate-moveWhiteDelayed">
            <Line />
          </div>

          <div className="absolute bottom-0 animate-moveBottomWhite">
            <LineBottom />
          </div>
          <div className="absolute bottom-0 animate-moveBottomWhiteDelayed">
            <LineBottom />
          </div>

          <LeaderboardBG2 />

          <div className="absolute -bottom-[300px] left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 items-end justify-center gap-[40px]">
            <div className="-mb-[50px]">
              <div className="-mb-[25px] w-[125px] h-[144px] bg-transparent cursor-pointer group perspective">
                <div className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000">
                  <div className="absolute backface-hidden w-full h-full">
                    <SilverMedel />
                  </div>
                  <div className="absolute my-rotate-y-180 backface-hidden w-full h-full overflow-hidden">
                    <div className="relative">
                      <Avatar
                        className="absolute top-[20px] left-[23.7px] shrink-0 rounded-full object-cover"
                        src={leaderboardList[1]?.profile_pic}
                        name={leaderboardList[1]?.name}
                        size="30"
                      />
                      <div className="absolute bottom-[25px] text-center -space-y-1 w-full">
                        <h3 className="text-[12px] text-white mx-auto">
                          <span>{leaderboardList[1]?.name?.split(" ")?.at(0)} </span>
                          <span>{leaderboardList[1]?.name?.split(" ")?.slice(1)?.join(" ")}</span>
                        </h3>
                        <p className="text-[10px] text-white">{leaderboardList[1]?.total_score}</p>
                      </div>
                      <SilverFlip />
                    </div>
                  </div>
                </div>
              </div>
              <DownArrow2 />
            </div>

            <div className="">
              <div className="-mb-[25px] w-[125px] h-[144px] bg-transparent cursor-pointer group perspective">
                <div className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000">
                  <div className="absolute backface-hidden w-full h-full">
                    <GoldMedal />
                  </div>
                  <div className="absolute my-rotate-y-180 backface-hidden w-full h-full overflow-hidden">
                    <div className="relative">
                      <Avatar
                        className="absolute top-[20px] left-[23.7px] shrink-0 rounded-full object-cover"
                        src={leaderboardList[0]?.profile_pic}
                        name={leaderboardList[0]?.name}
                        size="30"
                      />
                      <div className="absolute bottom-[25px] text-center -space-y-1 w-full">
                        <h3 className="text-[12px] text-white mx-auto">
                          <span>{leaderboardList[0]?.name?.split(" ")?.at(0)} </span>
                          <span>{leaderboardList[0]?.name?.split(" ")?.slice(1)?.join(" ")}</span>
                        </h3>
                        <p className="text-[10px] text-white">{leaderboardList[0]?.total_score}</p>
                      </div>
                      <GoldFlip />
                    </div>
                  </div>
                </div>
              </div>
              <DownArrow1 />
            </div>

            <div className="-mb-[50px]">
              <div className="-mb-[25px] w-[125px] h-[144px] bg-transparent cursor-pointer group perspective">
                <div className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000">
                  <div className="absolute backface-hidden w-full h-full">
                    <BronzeMedal />
                  </div>
                  <div className="absolute my-rotate-y-180 backface-hidden w-full h-full overflow-hidden">
                    <div className="relative">
                      <Avatar
                        className="absolute top-[20px] left-[23.7px] shrink-0 rounded-full object-cover"
                        src={leaderboardList[2]?.profile_pic}
                        name={leaderboardList[2]?.name}
                        size="30"
                      />
                      <div className="absolute bottom-[25px] text-center -space-y-1 w-full">
                        <h3 className="text-[12px] text-white mx-auto">
                          <span>{leaderboardList[2]?.name?.split(" ")?.at(0)} </span>
                          <span>{leaderboardList[2]?.name?.split(" ")?.slice(1)?.join(" ")}</span>
                        </h3>
                        <p className="text-[10px] text-white">{leaderboardList[2]?.total_score}</p>
                      </div>
                      <BronzeFlip />
                    </div>
                  </div>
                </div>
              </div>
              <DownArrow3 />
            </div>
          </div>
        </div>

        <div className="bg-white relative z-5">
          <div className="space-y-4 pt-[16px] px-6 pb-8 ">
            <div className="space-y-2">
              <h2 className="text-new-accent">{STR_LEADERBOARD}</h2>
              <p>{STR_LEADERBOARD_SUBTITLE}</p>
            </div>
            <Table data={tableData} student_email={student?.email} />
          </div>
        </div>
      </div>
    </DashboardLayoutv2>
  )
}
