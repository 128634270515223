import CopyButton from "core/components/Button"
import RedeemButton from "core/components/new/Button"
import { CoinIcon, CopyIcon } from "core/constants/svgs"
import { format } from "date-fns"
import React, { useEffect } from "react"
import amazon_vocher from "assets/images/amazon_voucher.png"
import extra_mentor_session from "assets/images/extra_mentor_session.png"
import FreezeActive from "assets/svgs/FreezeActive"
import PointIcon from "assets/svgs/PointIcon"
import CopyIconV2 from "assets/svgs/v2/CopyIcon"
import { STR_SUCCESS } from "core/constants/strings"

export default function OrderStatusCard({ order, changeToastVisibility, changeToastDetails }: any) {
  function handleCopyCoupon() {
    changeToastDetails(STR_SUCCESS, "Copied to clipboard")
    changeToastVisibility(true)
    navigator.clipboard.writeText(order?.coupon_id)
  }
  const amountToCoinsMap: { [key: number]: string } = {
    50: "10000",
    75: "15000",
    100: "19000",
    200: "35000",
    500: "90000",
  }
  // const expiry = format(order.order_date * 1000, "do MMM, yyyy");
  return (
    <div className="flex h-[162px] overflow-hidden rounded-[8px] p-[16px] border-[#DEDEDE] border-[0.5px] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.05)]">
      <div className="h-full w-full max-w-[240px]">
        <img className="h-full rounded-[8px]" src={amazon_vocher} alt="" />
      </div>
      <div className="flex w-full justify-between pl-6">
        <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
          <div className="flex justify-between">
            <div className="space-y-[4px]">
              <p className="text-[#646464] text-[14px] font-[400]">Order ID : #{order?.order_id}</p>
              <p className="text-[#0C0C0C] text-[18px] font-[600]">Amazon Coupon ({order?.amount} Rupees)</p>
              <div className="flex">
                <span className="text-[#646464] text-[12px] font-[600]">Date of Order / Expiry : </span>
                <span className="text-[#646464] text-[12px] font-[600]">
                  {format(order?.order_date * 1000, "do MMM, yyyy")} -{" "}
                  {format(order?.expiry_date * 1000, "do MMM, yyyy")}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 self-start">
              {order?.freeze_coins ? (
                <>
                  <FreezeActive className="h-6 w-6" />
                  <div className="px-[8px] py-[4px] bg-[#EEF8FD]">
                    <span className="text-xl font-bold text-[#1C3474]">{order?.freeze_coins}</span>
                  </div>
                </>
              ) : (
                <>
                  <span>
                    <PointIcon className="h-6 w-6" />
                  </span>
                  <div className="px-[8px] py-[4px] bg-[#FEFFE4]">
                    <span className="text-[14px] font-[600] text-[#FBBF24]">{amountToCoinsMap[order?.amount]}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-y-[2px]">
              <div className="text-[#D3D3D3] text-[12px] font-[600]">Coupon Code</div>
              <div className="flex gap-[4px] items-center font-medium text-[#525252]">
                <div className="bg-[#F6F6F6] rounded-[4px] px-[6px] py-[4px]">
                  <span className="text-[14px] font-[600] w-[320px]">{order?.coupon_id}</span>
                </div>
                <CopyButton outlined onClick={handleCopyCoupon} className={"border-0 px-2 py-0"}>
                  <CopyIconV2 />
                </CopyButton>
              </div>
            </div>
            <p className="text-[#07B42D] font-[600] text-[18px]">Redeem Successful</p>
          </div>
        </div>
      </div>
    </div>
  )
}
