import { useEffect } from "react"
import useCourseSessionViewModel from "./CourseSessionViewModel"
import RecordedSessionFeedback from "./components/RecordedSessionFeedback"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import CourseDisclosure from "./components/CourseDisclosure"
import CourseSidebar from "./components/CourseSidebar"
import { cn } from "core/lib/utils"
import Loader from "core/components/Loader"
import { STR_VIEW_NOTES, STR_VIEW_SUMMARY } from "core/constants/strings"
import { CheckSuccessIcon } from "core/constants/svgs"
import Modal from "core/components/Modal"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import SubmissionResponse from "./components/SubmissionResponse"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import Tags from "core/components/v2/Tags"
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeader } from "core/components/v2/Table"
import { Link, useSearchParams } from "react-router-dom"
import TableButton from "core/components/v2/TableButton"
import ArrowBack from "core/components/v2/ArrowBack"
import { Head } from "core/components/seo"
import AISubmissionResponse from "./components/AISubmissionResponse"
import StudentFeedback from "./components/StudentFeedback"

export default function CourseSession() {
  const {
    toast,
    assignmentsTableContent,
    warmupTableContent,
    homeworkTableContent,
    practiceTableContent,
    sessionDetails,
    courseLectures,
    loading,
    activeIndex,
    sessionId,
    loadingTable,
    sessionList,
    courseName,
    refreshed,
    feedbackData,
    isRecordedFeedbackVisible,
    isViewSubmissionVisible,
    order,
    questionName,
    isLoading,
    questionType,
    feedbackResponseData,
    selectedQuestion,
    tab,
    assignmenTab,
    TABS,
    AssignmentTabs,
    onLectureClick,
    fetchSessionDetails,
    fetchCourseLectures,
    getFeedbackDetails,
    setIsRecordedFeedbackVisible,
    submitRecordingFeedback,
    changeToastVisibility,
    handleSidebarData,
    changeTableData,
    navigate,
    handleOnCloseBtnModalClick,
    fetchViewSubmission,
    setTab,
    handleTabChange,
    handleAssignmentTabChange,
    handleSelectedQuestionBtnClick,
    isExternalProblem,
    handleFeedbackBtnClick,
    handleSubjectiveFeedbackBtnClick,
    isViewSubjectiveSubmissionVisible,
    handleOnCloseBtnSubmitModalClick,
    rateAIModal,
    fetchViewSubjectiveSubmission,
    setIsStudentFeedbackVisible,
    isStudentFeedbackVisible,
    subjectivefeedbackResponseData,
    handleOnCloseFeedbackBtnClick,
    submitStudentFeedback,
  } = useCourseSessionViewModel()

  useEffect(() => {
    if (refreshed) {
      fetchSessionDetails()
    }
  }, [refreshed, sessionId])

  useEffect(() => {
    if (refreshed && sessionList[sessionId]) {
      fetchCourseLectures()
      handleSidebarData()
      getFeedbackDetails(sessionId)
    }
  }, [refreshed, sessionList])

  useEffect(() => {
    changeTableData(sessionDetails)
  }, [loadingTable, sessionDetails])

  useEffect(() => {
    if (refreshed && isViewSubmissionVisible) {
      fetchViewSubmission(questionName, questionType)
    }
  }, [refreshed, isViewSubmissionVisible, questionName, questionType])

  useEffect(() => {
    if (refreshed && isViewSubjectiveSubmissionVisible && questionType === "subjective") {
      fetchViewSubjectiveSubmission(questionName)
    } else if (refreshed && isViewSubmissionVisible) {
      fetchViewSubmission(questionName, questionType)
    }
  }, [refreshed, isViewSubmissionVisible, isViewSubjectiveSubmissionVisible, questionName, questionType])

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const currentTabVisible = searchParams.get("tab")
    if (currentTabVisible) {
      setTab(parseInt(currentTabVisible, 0))
    }
  }, [searchParams])

  const duration = sessionDetails?.duration

  return (
    <DashboardLayoutv2>
      <Head title="Session" />

      <ConditionalLoader isLoading={loading} loader={<Loader />}>
        <div className="space-y-4 p-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <h3 className="text-new-accent">{sessionDetails?.session_name}</h3>
              <p>
                Duration:{" "}
                {typeof duration === "number"
                  ? `${Math.floor(duration / 3600)}h:${Math.floor((duration % 3600) / 60)}m:
                ${duration % 60}s`
                  : duration}
              </p>
            </div>
            <TableButton
              className="flex h-fit w-fit items-center justify-center gap-1"
              outlined
              onClick={() => navigate("/course")}
            >
              <ArrowBack />
              <p className="text-sm font-medium">Go Back</p>
            </TableButton>
          </div>
          <div className="flex gap-6">
            <div className="w-[72%] space-y-6">
              <div className="flex gap-2">
                {TABS.map((item: string, idx: number) => (
                  <Tags selected={tab === idx} onClick={() => handleTabChange(idx)}>
                    {item}
                  </Tags>
                ))}
              </div>
              {tab === 0 && (
                <div className="h-calc((w-full)*0.5625) w-full">
                  {sessionDetails?.video_id ? (
                    <iframe
                      title="Video"
                      className="aspect-video h-full w-full rounded"
                      src={sessionDetails?.video_link}
                      allow="autoplay; fullscreen; picture-in-picture"
                    />
                  ) : (
                    <div className="flex aspect-video h-full w-full items-center justify-center font-medium">
                      <span>Video not available!</span>
                    </div>
                  )}
                </div>
              )}
              {tab === 1 && (
                <div>
                  <div className="flex gap-2 mb-[16px]">
                    {AssignmentTabs.map((item: any, idx: number) => (
                      <Tags selected={assignmenTab === item.key} onClick={() => handleAssignmentTabChange(item.key)}>
                        {item.value}
                      </Tags>
                    ))}
                  </div>
                  {assignmenTab === 0 && <div>{warmupTableContent?.content}</div>}

                  {assignmenTab === 1 && <div className="">{assignmentsTableContent?.content}</div>}

                  {assignmenTab === 2 && <div>{homeworkTableContent?.content}</div>}

                  {assignmenTab === 3 && <div>{practiceTableContent?.content}</div>}
                </div>
              )}
              {tab === 2 && (sessionDetails?.notes_link || sessionDetails?.notes_summary_link) && (
                <div className="flex items-start gap-4">
                  <button className="mt-4 text-new-success">
                    <CheckSuccessIcon className="h-4 w-4" />
                  </button>
                  <CourseDisclosure name="Notes">
                    <div className="flex gap-2">
                      {sessionDetails?.notes_link && sessionDetails?.notes_link.length > 0 && (
                        <a href={sessionDetails?.notes_link} target="_blank" rel="noreferrer">
                          <Button outlined>{STR_VIEW_NOTES}</Button>
                        </a>
                      )}
                      {sessionDetails?.notes_summary_link && sessionDetails?.notes_summary_link.length > 0 && (
                        <a href={sessionDetails?.notes_summary_link} target="_blank" rel="noreferrer">
                          <Button outlined>{STR_VIEW_SUMMARY}</Button>
                        </a>
                      )}
                    </div>
                  </CourseDisclosure>
                </div>
              )}
            </div>
            <div className="w-[28%]">
              <CourseSidebar {...{ lectures: courseLectures, courseName, activeIndex }} onClick={onLectureClick} />
            </div>
          </div>
        </div>
      </ConditionalLoader>
      <Modal
        open={isViewSubjectiveSubmissionVisible}
        onClose={handleOnCloseBtnSubmitModalClick}
        width="w-[452px]"
        height={true}
      >
        <AISubmissionResponse
          feedbackData={subjectivefeedbackResponseData}
          isLoading={isLoading}
          onClose={handleOnCloseBtnSubmitModalClick}
          rateAIModal={rateAIModal}
        />
      </Modal>

      <Modal open={isStudentFeedbackVisible} onClose={handleOnCloseFeedbackBtnClick} width="w-[450px]" height={true}>
        <StudentFeedback
          questionName={questionName}
          onClose={handleOnCloseFeedbackBtnClick}
          isLoading={isLoading}
          onSubmit={submitStudentFeedback}
        />
      </Modal>

      <Modal open={isRecordedFeedbackVisible}>
        <RecordedSessionFeedback
          onSubmit={submitRecordingFeedback}
          isRecordedFeedbackVisible={isRecordedFeedbackVisible}
          setIsRecordedFeedbackVisible={setIsRecordedFeedbackVisible}
          feedbackData={feedbackData}
        />
      </Modal>

      <Modal
        open={isViewSubmissionVisible}
        onClose={handleOnCloseBtnModalClick}
        width={questionType === "casestudy" ? true : false}
        padding={questionType === "casestudy" ? true : false}
      >
        <SubmissionResponse
          feedbackData={feedbackResponseData}
          order={order}
          isLoading={isLoading}
          questionName={questionName}
          questionType={questionType}
          selectedQuestion={selectedQuestion}
          handleSelectedQuestionBtnClick={handleSelectedQuestionBtnClick}
        />
      </Modal>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
