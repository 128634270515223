import DashboardLayout from "core/layouts/DashboardLayout"
import useViewModel from "./ViewModel"
import Input from "core/components/new/Input"
import { useEffect } from "react"
import Table from "core/components/new/Table"
import { isEmpty } from "core/utils/misc"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import Select from "core/components/new/Select"
import { cn } from "core/lib/utils"
import StandardSelect from "core/components/v2/StandardSelect"

export default function QuestionsView() {
  const {
    searchQuestion,
    refreshed,
    inputRef,
    tableData,
    questions,
    setQuestions,
    toast,
    pageLoading,
    rowsVisible,
    pageNumber,
    options_rows,
    changeToastVisibility,
    handleChangeSearchQuestion,
    getQuestions,
    handleTableData,
    navigate,
    handleChangeVisibleRows,
    handleChangePageNumber,
    tagList,
    selectedTag,
    setSelectedTag,
  } = useViewModel()

  useEffect(() => {
    if (refreshed) {
      getQuestions()
      inputRef.current?.focus()
    }
  }, [refreshed])

  useEffect(() => {
    if (!isEmpty(questions)) {
      handleTableData()
    }
  }, [questions, searchQuestion, rowsVisible, pageNumber, selectedTag])

  useEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Questions Dashboard</h2>
          <p>All questions collection to view, edit and add new ones</p>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4">
          <Input
            id="search-question"
            ref={inputRef}
            placeholder="Search question"
            value={searchQuestion}
            onChange={handleChangeSearchQuestion}
            containerClass="w-[300px]"
            search
          />
          <StandardSelect
            dropdownClass="h-[300px] overflow-y-scroll"
            className=" px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            options={tagList}
            placeholder="Select Tag"
            name="tag"
            onChange={(tag: string) => {
              setSelectedTag(tag)
            }}
            value={selectedTag}
          />
          <Button outlined className="max-w-[150px]" onClick={() => navigate("add")}>
            Add Question
          </Button>
          <Select
            className="ml-auto w-20"
            options={options_rows}
            value={rowsVisible}
            defaultOption="Rows"
            onChange={handleChangeVisibleRows}
          />
        </div>
        <ConditionalLoader isLoading={pageLoading} loader={<Loader small />}>
          <Table data={tableData} />
          <div className="justify-center flex mx-auto max-w-xl">
            <div className="flex gap-2 w-[inherit] overflow-x-auto">
              {[...Array(Math.ceil(questions.length / rowsVisible))].map((_, i) => (
                <button
                  key={i}
                  className={cn(
                    "border border-new-accent shrink-0 inline-flex items-center justify-center min-w-[16px] h-6 p-2 hover:bg-new-accent hover:text-new-solid-white",
                    pageNumber === i + 1 ? "bg-new-accent text-new-solid-white" : "text-new-accent"
                  )}
                  onClick={() => handleChangePageNumber(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        </ConditionalLoader>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
