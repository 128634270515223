// studentplacementdashboard
import PlacementBatchDataSource from "data/dataSource/Admin/PlacementBatchDataSource"
import { Auth } from "domain/model/Auth"
import { PlacementBatch } from "domain/model/PlacementBatch"
import { PlacementBatchRepository } from "domain/repository/Admin/PlacementBatchRepository"
export class PlacementBatchRepositoryImpl implements PlacementBatchRepository {
  private Datasource: PlacementBatchDataSource

  constructor(Datasource: PlacementBatchDataSource) {
    this.Datasource = Datasource
  }

  async createBatch(createBatch: PlacementBatch): Promise<any> {
    return await this.Datasource.createPlacementBatch(createBatch)
  }

  async addCohortStudent(
    auth: Auth,
    contestId: string,
    placementBatchName: string,
    bridgeBatchName: string,
    created_on: number
  ): Promise<any> {
    return await this.Datasource.addCohortStudent(auth, contestId, placementBatchName, bridgeBatchName, created_on)
  }

  async addIsCohortDbFieldStored(auth: Auth, contestId: string): Promise<any> {
    return await this.Datasource.addIsCohortDbFieldStored(auth, contestId)
  }
}
