import SheetsDataSource from "data/dataSource/Admin/SheetsDataSource"
import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export class SheetsRepositoryImpl implements SheetsRepository {
  private dataSource: SheetsDataSource

  constructor(dataSource: SheetsDataSource) {
    this.dataSource = dataSource
  }

  async getBatchStudentDetails(data: any): Promise<any> {
    return await this.dataSource.getBatchStudentDetails(data)
  }

  async getBatchYearData(auth: Auth, batch: string, month: any, year: string): Promise<any> {
    return await this.dataSource.getBatchYearData(auth, batch, month, year)
  }

  async getMentorListData(auth: Auth, batch: string): Promise<any> {
    return await this.dataSource.getMentorListData(auth, batch)
  }

  async getMentorInvoices(auth: Auth, year: string, month: string): Promise<any> {
    return await this.dataSource.getMentorInvoices(auth, year, month)
  }

  async getInstructorInvoices(auth: Auth, year: string, month: string): Promise<any> {
    return await this.dataSource.getInstructorInvoices(auth, year, month)
  }

  async getMentorInstructorInvoices(auth: Auth, year: string, month: string): Promise<any> {
    return await this.dataSource.getMentorInstructorInvoices(auth, year, month)
  }

  async getDoubtRaisedStats(data: any): Promise<any> {
    return await this.dataSource.getDoubtRaisedStats(data)
  }

  async updateBatchData(auth: Auth, batch: string): Promise<any> {
    return await this.dataSource.updateBatchData(auth, batch)
  }

  async allotMentors(auth: Auth, batch: any): Promise<any> {
    return await this.dataSource.allotMentors(auth, batch)
  }
}
