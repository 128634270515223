// studentplacementdashboard
import PlacementDashboardDataSource from "data/dataSource/Admin/PlacementDashboardDataSource"
import { Auth } from "domain/model/Auth"
import { PlacementBatch } from "domain/model/PlacementBatch"
import { AllStudentsReqType } from "domain/model/PlacementDashboard"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export class PlacementDashboardRepositoryImpl implements PlacementDashboardRepository {
  private Datasource: PlacementDashboardDataSource

  constructor(Datasource: PlacementDashboardDataSource) {
    this.Datasource = Datasource
  }

  async createBatch(createBatch: PlacementBatch): Promise<any> {
    return await this.Datasource.createPlacementBatch(createBatch)
  }

  async addCohortStudent(auth: Auth, contestId: string): Promise<any> {
    return await this.Datasource.addCohortStudent(auth, contestId)
  }

  async addIsCohortDbFieldStored(auth: Auth, contestId: string): Promise<any> {
    return await this.Datasource.addIsCohortDbFieldStored(auth, contestId)
  }

  async getStudentList(auth: Auth, data: AllStudentsReqType): Promise<any> {
    return await this.Datasource.getStudentList(auth, data)
  }

  async getStudentDetails(auth: Auth, docId: string): Promise<any> {
    return await this.Datasource.getStudentDetails(auth, docId)
  }

  async getReviewsById(auth: Auth, docId: string): Promise<any> {
    return await this.Datasource.getReviewsById(auth, docId)
  }

  async editReview(auth: Auth, docId: string, updatedReview: any): Promise<any> {
    return await this.Datasource.editReview(auth, docId, updatedReview)
  }

  async getJourneysById(auth: Auth, docId: string): Promise<any> {
    return await this.Datasource.getJourneysById(auth, docId)
  }

  async editStudentDetails(auth: Auth, docId: string, updatedDetails: any): Promise<any> {
    return await this.Datasource.editStudentDetails(auth, docId, updatedDetails)
  }

  async uploadResume(auth: Auth, docId: string, resumeFile: any): Promise<any> {
    return await this.Datasource.uploadResume(auth, docId, resumeFile)
  }

  async editPlacementStatus(auth: Auth, docId: string, statusType: string, newStatus: string): Promise<any> {
    return await this.Datasource.editPlacementStatus(auth, docId, statusType, newStatus)
  }
}
