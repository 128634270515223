import Button from "core/components/new/Button"
import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useEffect, useState } from "react"
import SheetsTable from "./components/SheetsTable"
import useSheetsViewModel from "./HomeViewModel"
import SheetsModal from "./components/SheetsModal"
import { cn } from "core/lib/utils"
import { useNavigate } from "react-router-dom"
import Input from "core/components/new/Input"
import ProductAnalysis from "./components/ProductAnalysis"
import SheetsView from "../Sheets/SheetsView"
import StandaredSelect from "core/components/StandaredSelect"
import ViewDataTable from "./components/ViewDataTable"
import Toast from "core/components/Toast"
import { STR_SUCCESS } from "core/constants/strings"

export default function HomeView() {
  const {
    refreshed,
    data,
    batchChangeDetails,
    mentorChangeDetails,
    isOpen,
    numberOfPages,
    itemPerPage,
    pageNumber,
    coursePauseDetails,
    courseResumeDetails,
    studentDoubtDetails,
    reason,
    emailInput,
    allBatches,
    allMentors,
    fromDate,
    toDate,
    autoTasks,
    index,
    batch1,
    batch2,
    batch,
    status,
    email,
    productSheet,
    startDate,
    endDate,
    productAnalysisLoading,
    productAnalysisCheck,
    productAnalytics,
    handleStartDate,
    handleEndDate,
    setBatchChangeDetails,
    setMentorChangeDetails,
    setCoursePauseDetails,
    setCourseResumeDetails,
    setStudentDoubtDetails,
    handleStatusChange,
    setStatus,
    setBatch1,
    setBatch2,
    setBatch,
    setIndex,
    setEmailInput,
    setFromDate,
    setToDate,
    setPageNumber,
    setIsOpen,
    handleItemPerPage,
    hanldeProductSheets,
    handleBatchData,
    setBatchData,
    batchData,
    handleBatchStudentChange,
    batchStudentData,
    setBatchStudentData,
    fetchProductAnalytics,
    handleEmailChange,
    fetchAllBatches,
    fetchAllMentors,
    handleFromDateChange,
    handleToDateChange,
    renderFilterData,
    setItemPerPage,
    autoTask,
    setAutoTask,
    mentor,
    setMentor,
    prevMentor,
    setPrevMentor,
    pausedBatch,
    setPausedBatch,
    prevBatch,
    setPrevBatch,
    setToTimestamp,
    setFromTimestamp,
    batchWiseStudentList,
    changeToastVisibility,
    changeToastDetails,
    isLoading,
    setIsLoading,
    batchTimestampDetails,
    handleBatchTimestampData,
    fetchbatch3,
    handleFormSubmitTimestamp,
    blurSelect,
    allBatches3,
    handleBatch3Change,
    batch3,
    submitForm,
    buttonDisabled,
    handleViewBatchData,
    formmatedData,
    toast,
  } = useSheetsViewModel()
  const numberOfPagesOptions = [10, 20, 50, 100]
  const [isTaskChanged, setIsTaskChanged] = useState(false)
  const array = Array.from({ length: numberOfPages }, (_, index) => index + 1)
  const navigate = useNavigate()

  useEffect(() => {
    setPageNumber(0)
  }, [itemPerPage])

  useEffect(() => {
    setPageNumber(0)
    setItemPerPage(10)
  }, [index, batch1, batch2, emailInput, fromDate, toDate, status])

  useEffect(() => {
    setBatchChangeDetails({ header: batchChangeDetails.header, rows: [] })
    setMentorChangeDetails({ header: mentorChangeDetails.header, rows: [] })
    setCoursePauseDetails({ header: coursePauseDetails.header, rows: [] })
    setCourseResumeDetails({ header: courseResumeDetails.header, rows: [] })
    setStudentDoubtDetails({ header: studentDoubtDetails.header, rows: [] })
  }, [index, batch1, batch2, emailInput, fromDate, toDate, status, itemPerPage, pageNumber])

  useEffect(() => {
    setIsTaskChanged(!isTaskChanged)
    setEmailInput("")
    setFromDate("")
    setToDate("")
    setBatch1("")
    setBatch2("")
    setStatus("")
    setPrevBatch("")
    setPausedBatch("")
    setBatch("")
    setPrevMentor("")
    setMentor("")
    setToTimestamp("")
    setFromTimestamp("")
  }, [index])

  useEffect(() => {
    if (refreshed && (autoTask || batchData)) {
      fetchAllBatches()
      fetchAllMentors()
    }
  }, [refreshed, autoTask, batchData])

  useEffect(() => {
    renderFilterData()
  }, [
    autoTask,
    isTaskChanged,
    pageNumber,
    itemPerPage,
    prevBatch,
    emailInput,
    status,
    toDate,
    fromDate,
    pausedBatch,
    mentor,
    prevMentor,
    batch,
  ])

  useEffect(() => {
    if (batchTimestampDetails) {
      fetchbatch3()
    }
  }, [batchTimestampDetails])

  useEffect(() => {
    fetchbatch3()
  }, [])

  const handleBatchChange = (value: string, type: string) => {
    if (type === "batch") {
      setBatch(value)
    } else if (type === "curr_mentor") {
      setMentor(value)
    }
  }

  const handlePrevBatch_MentorChange = (value: string, type: string) => {
    switch (type) {
      case "batch":
        setBatch(value)
        break
      case "prev_batch":
        setPrevBatch(value)
        break
      case "paused_batch":
        setPausedBatch(value)
        break
      case "prev_mentor":
        setPrevMentor(value)
        break
      default:
        break
    }
  }

  type DataByIndex = {
    studentDoubtDetails: any
    batchChangeDetails: any
    coursePauseDetails: any
    courseResumeDetails: any
    mentorChangeDetails: any
  }

  function getDataByIndex(index: number, data: DataByIndex) {
    switch (index) {
      case 0:
        return data.studentDoubtDetails
      case 1:
        return data.batchChangeDetails
      case 2:
        return data.coursePauseDetails
      case 3:
        return data.courseResumeDetails
      case 4:
        return data.mentorChangeDetails
      default:
        return null
    }
  }

  function getValueByIndex(
    index: number,
    batch: string,
    prevBatch: string,
    pausedBatch: string,
    prevMentor: string
  ): string {
    switch (index) {
      case 0:
        return batch
      case 1:
      case 3:
        return prevBatch
      case 2:
        return pausedBatch
      case 4:
        return prevMentor
      default:
        return ""
    }
  }

  function getKeywordByIndex(index: number): string {
    switch (index) {
      case 0:
        return "batch"
      case 1:
      case 3:
        return "prev_batch"
      case 2:
        return "paused_batch"
      case 4:
        return "prev_mentor"
      default:
        return ""
    }
  }

  const handleDocumentationClick = () => {
    navigate("/admin/documentation_files")
  }
  return (
    <DashboardLayout>
      {isOpen && <SheetsModal setIsOpen={setIsOpen} reason={reason} task={autoTasks[index]} email={email} />}

      {!autoTask && !productSheet && !batchData && !batchTimestampDetails && (
        <div className="flex gap-x-2 p-6">
          <Button
            className="w-fit"
            onClick={() => {
              setAutoTask(true)
            }}
          >
            AutoTask Tracker
          </Button>
          <Button className="w-fit" onClick={handleDocumentationClick}>
            Codebase Documentation
          </Button>
          <Button className="w-fit" onClick={hanldeProductSheets}>
            Product Analytics
          </Button>
          <Button className="w-fit" onClick={handleBatchData}>
            Get Active Students List
          </Button>

          <Button className="w-fit" onClick={handleBatchTimestampData}>
            Batch Details
          </Button>
        </div>
      )}

      {batchData && (
        <div className="mx-4 my-7 space-y-4">
          <h3>Get Active Students List of a Batch</h3>
          <div className="max-w-md space-x-4">
            <form className="space-y-4" onSubmit={batchWiseStudentList}>
              <div className="space-y-2 text-new-neutral-dark">
                <label
                  htmlFor="batch"
                  className="after-star w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark"
                >
                  Batch
                </label>
                <StandaredSelect
                  required
                  name="batch3"
                  id="batch3"
                  options={allBatches}
                  placeholder="Select Batch"
                  onChange={handleBatchStudentChange}
                  value={batchStudentData}
                />
              </div>
              <Button loading={isLoading} disabled={isLoading}>
                Download
              </Button>
            </form>
          </div>
        </div>
      )}

      {productSheet && (
        <div className="">
          <div className="mx-4 my-7 space-y-4">
            <h3>Get Product Analytics</h3>
            <div className="max-w-md space-x-4">
              <form className="space-y-4" onSubmit={fetchProductAnalytics}>
                <Input
                  required
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  value={startDate}
                  placeholder="Choose Date"
                  label="Start Date"
                  onChange={handleStartDate}
                />
                <Input
                  required
                  type="date"
                  name="toDate"
                  id="toDate"
                  value={endDate}
                  placeholder="Choose Date"
                  label="End Date"
                  onChange={handleEndDate}
                />
                <Button
                  loading={productAnalysisLoading}
                  disabled={productAnalysisLoading}
                  onClick={fetchProductAnalytics}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
          {productAnalysisCheck && <ProductAnalysis productAnalysis={productAnalytics} />}
        </div>
      )}

      {!autoTask && !batchTimestampDetails && <SheetsView />}

      {autoTask && (
        <div className="mx-4 mt-4 flex flex-col gap-4 ">
          <div className="relative flex gap-4">
            <div className="flex flex-col">
              <div className="text-lg font-semibold">Email Id</div>
              <input
                type="text"
                value={emailInput}
                onChange={(e: any) => handleEmailChange(e)}
                placeholder="User Email ID"
                className="h-[33px]"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-lg font-semibold">Date</div>
              <input
                type="date"
                name="fromDate"
                id="fromDate"
                value={fromDate}
                placeholder="Choose Date"
                onChange={handleFromDateChange}
              />
              <input
                type="date"
                name="toDate"
                id="toDate"
                value={toDate}
                placeholder="Choose Date"
                onChange={handleToDateChange}
              />
            </div>

            <div className="flex flex-col">
              <div className="text-lg font-semibold">
                {index === 0
                  ? "Batch"
                  : index === 1 || index === 3
                    ? "Prev Batch"
                    : index === 2
                      ? "Paused Batch"
                      : index === 4 && "Prev Mentor"}
              </div>
              <select
                className="h-[33px] text-sm"
                value={getValueByIndex(index, batch, prevBatch, pausedBatch, prevMentor)}
                onChange={(e) => handlePrevBatch_MentorChange(e.target.value, getKeywordByIndex(index))}
              >
                <option value="" disabled>
                  Select a {index === 4 ? "Mentor" : "Batch"}
                </option>
                {(index === 4 ? allMentors : allBatches)?.map((item: any, i: number) => (
                  <option key={i} className="bg-neutral-50 text-sm text-neutral-500" value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            {index !== 2 && (
              <div className="flex flex-col">
                <div className="text-lg font-semibold">
                  {index === 0 ? "Status" : index === 1 || index === 3 ? "Curr Batch" : index === 4 && "Curr Mentor"}
                </div>
                {index === 0 ? (
                  <select className="h-[33px] text-sm" value={status} onChange={handleStatusChange}>
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="Helpful" className="bg-neutral-50 text-sm text-neutral-500">
                      Helpful
                    </option>
                    <option value="Not Helpful" className="bg-neutral-50 text-sm text-neutral-500">
                      Not Helpful
                    </option>
                    <option value="Resolved" className="bg-neutral-50 text-sm text-neutral-500">
                      Resolved
                    </option>
                    <option value="Not Applicable" className="bg-neutral-50 text-sm text-neutral-500">
                      Not Applicable
                    </option>
                  </select>
                ) : (
                  <select
                    className="h-[33px] text-sm"
                    value={index === 4 ? mentor : batch}
                    onChange={(e) =>
                      handleBatchChange(
                        e.target.value,
                        index === 1 || index === 3 ? "batch" : index === 4 ? "curr_mentor" : ""
                      )
                    }
                  >
                    <option value="" disabled>
                      Select a {index === 4 ? "Mentor" : "Batch"}
                    </option>
                    {(index === 4 ? allMentors : allBatches)?.map((item: any, i: number) => (
                      <option key={i} className="bg-neutral-50 text-sm text-neutral-500" value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            )}
            <div className="absolute right-4 flex flex-col gap-y-4">
              <button
                className="border-1 rounded-sm border border-[#312E81] p-2 text-sm font-medium text-[#312E81] hover:bg-[#312E81] hover:text-white"
                onClick={() => {
                  setEmailInput("")
                  setFromDate("")
                  setToDate("")
                  setBatch1("")
                  setBatch2("")
                  setStatus("")
                  setPrevBatch("")
                  setBatch("")
                  setPrevMentor("")
                  setMentor("")
                  setToTimestamp("")
                  setFromTimestamp("")
                  setIsTaskChanged(!isTaskChanged)
                }}
              >
                Clear Filters
              </button>
              <div className="relative flex flex-col">
                <div className="text-sm font-medium">Select no of entries:</div>
                <select value={itemPerPage} onChange={handleItemPerPage} className="absolute right-0 top-6 w-[100px]">
                  {numberOfPagesOptions.map((pages: number, i: number) => (
                    <option key={i} value={pages}>
                      {pages}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex gap-x-[10px]">
            {autoTasks.map((task: any, i: number) => (
              <div
                className={cn(
                  "border-1 flex h-[35px] w-[157px] cursor-pointer items-center justify-center rounded-sm border text-sm font-medium",
                  i === index && "bg-[#312E81] text-white"
                )}
                onClick={() => {
                  setIndex(i)
                }}
              >
                {task}
              </div>
            ))}
          </div>
          <SheetsTable data={getDataByIndex(index, data)} outlined />

          <div className="custom-scrollbar relative mx-auto mb-[50px] flex max-w-5xl overflow-x-scroll">
            <div className="flex transform gap-2">
              {array.map((num: any, i: number) => (
                <div className="">
                  <button
                    className={cn("h-10 w-10 border", pageNumber === i && "bg-[#312E81] text-white")}
                    onClick={() => {
                      setPageNumber(i)
                    }}
                  >
                    {i + 1}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* batch time stamp details component download csv , view data  */}
      {batchTimestampDetails && (
        <div className="mx-4 mt-4 flex flex-col gap-4 ">
          <div className="relative flex gap-4">
            <form className="flex space-x-4" onSubmit={handleFormSubmitTimestamp}>
              <div className="flex flex-col gap-y-2">
                <div className={`space-y-2 text-new-neutral-dark ${blurSelect ? "blur" : ""}`}>
                  <label
                    htmlFor="batch"
                    className="after-star w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark"
                  >
                    Batch
                  </label>
                  <StandaredSelect
                    name="batch"
                    id="batch"
                    className="flex"
                    options={allBatches3}
                    placeholder="Select Batch"
                    onChange={handleBatch3Change}
                    value={batch3}
                  />
                </div>
              </div>

              {!blurSelect && (
                <div className="flex  flex-col">
                  <div className=" text-lg font-semibold">Date</div>
                  <div className="flex gap-2 ">
                    <input
                      type="date"
                      name="fromDate"
                      id="fromDate"
                      className="rounded-sm h-9"
                      value={fromDate}
                      placeholder="Choose Date"
                      onChange={handleFromDateChange}
                    />
                    <input
                      type="date"
                      name="toDate"
                      id="toDate"
                      className="rounded-sm h-9"
                      value={toDate}
                      placeholder="Choose Date"
                      onChange={handleToDateChange}
                    />

                    <Button className="h-9" onClick={submitForm} loading={isLoading} disabled={buttonDisabled}>
                      Download
                    </Button>
                    <Button className="h-9" onClick={handleViewBatchData} loading={isLoading} disabled={isLoading}>
                      View
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="flex gap-x-[10px]"></div>
          <ViewDataTable
            data={formmatedData}
            outlined
            toast={toast}
            changeToastVisibility={changeToastVisibility}
            changeToastDetails={changeToastDetails}
          />

          <Toast data={toast} onClick={() => changeToastVisibility(false)} />
        </div>
      )}
    </DashboardLayout>
  )
}
