import { useState, useEffect } from "react"
import HomePageGradient from "core/components/new/HomePageGradient"
import { Button } from "core/components/v2/Button"
import { format } from "date-fns"
import { Tooltip } from "@mui/material"
import Flag from "core/components/Flag"

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return `${day}th`
  switch (day % 10) {
    case 1:
      return `${day}st`
    case 2:
      return `${day}nd`
    case 3:
      return `${day}rd`
    default:
      return `${day}th`
  }
}

const formatCustomDate = (date: Date): string => {
  const day = format(date, "d")
  const ordinalDay = getOrdinalSuffix(parseInt(day))
  const monthYear = format(date, "MMMM, yyyy")
  const time = format(date, "h:mm a")
  return `${ordinalDay} ${monthYear} | ${time}`
}

function convertAndAddWeek(dateStr: string) {
  const year = dateStr.substring(0, 4)
  const month = dateStr.substring(4, 6)
  const day = dateStr.substring(6, 8)

  const date = new Date(`${year}-${month}-${day}`)

  function formatDate(date: any) {
    const newYear = date.getFullYear()
    const newMonth = date.getMonth()
    const newDay = date.getDate()

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const monthName = months[newMonth]

    const formattedDay = newDay < 10 ? "0" + newDay : newDay

    return `${formattedDay} ${monthName} ${newYear}`
  }

  const formattedOriginalDate = formatDate(date)
  date.setDate(date.getDate() + 7)
  const formattedNewDate = formatDate(date)

  return {
    original: formattedOriginalDate,
    oneWeekLater: formattedNewDate,
  }
}

const Skeleton = ({ color }: any) => {
  return (
    <div className="relative w-full animate-pulse overflow-hidden rounded px-6 py-4 shadow">
      <div className="flex justify-between font-semibold">
        <div className="h-4 w-1/4 rounded bg-gray-300"></div>
        <div className="h-4 w-1/6 rounded bg-gray-300"></div>
      </div>
      <div className="mt-2 h-3 w-3/4 rounded bg-gray-300"></div>
      <div className="mt-1 h-3 w-1/2 rounded bg-gray-300"></div>
      <div className="mt-4 flex justify-end">
        <div className="h-8 w-24 rounded bg-gray-300"></div>
      </div>
      <div className="absolute top-0 left-[126px]">
        <HomePageGradient className={color} />
      </div>
      <div className="absolute top-0 left-[201px]">
        <HomePageGradient className={color} />
      </div>
      <div className="absolute top-0 left-[821px]">
        <HomePageGradient className={color} />
      </div>
    </div>
  )
}

const RemainderCard = ({ selectedTag, data, loading, color, handleRemainderCardClick }: any) => {
  const sessionTimestamp = data[selectedTag]?.start_time
  const sessionDuration = selectedTag === "Class" ? data[selectedTag]?.duration : 0
  const durationSecond = sessionDuration * 3600
  const buffer = selectedTag === "Class" ? 30 * 60 : 0

  let formattedDate: string | { original: string; oneWeekLater: string }

  if (selectedTag === "Progress Meet") {
    formattedDate = sessionTimestamp ? convertAndAddWeek(sessionTimestamp) : ""
  } else if (selectedTag === "Exam" || selectedTag === "Placement Cohort") {
    let startTime = sessionTimestamp ? formatCustomDate(new Date(sessionTimestamp * 1000)) : ""
    let durationTime = data[selectedTag]?.duration
      ? formatCustomDate(new Date((sessionTimestamp + data[selectedTag]?.duration * 60) * 1000))
      : ""
    formattedDate = `${startTime} - ${durationTime.split("|")[1]}`
  } else {
    formattedDate = sessionTimestamp ? formatCustomDate(new Date(sessionTimestamp * 1000)) : ""
  }

  const [timeLeftToStart, setTimeLeftToStart] = useState<any>(sessionTimestamp - Math.floor(Date.now() / 1000))
  const timeLeftWithBuffer = timeLeftToStart + durationSecond + buffer

  const [remainingTime, setRemainingTime] = useState(timeLeftWithBuffer)

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(timeLeftWithBuffer)
    }, 1000)

    const interval2 = setInterval(() => {
      const timeLeft = sessionTimestamp - Math.floor(Date.now() / 1000)
      if (timeLeft > 0) setTimeLeftToStart(sessionTimestamp - Math.floor(Date.now() / 1000))
      else clearInterval(interval2)
    }, 45 * 1000)

    return () => {
      clearInterval(interval)
      clearInterval(interval2)
    }
  }, [sessionTimestamp, selectedTag])
  function formatTime() {
    const hours = Math.floor(timeLeftToStart / (60 * 60))
    const minutes = Math.floor((timeLeftToStart / 60) % 60)

    if (hours > 24)
      return Math.floor(hours / 24) === 1 ? Math.floor(hours / 24) + " day" : Math.floor(hours / 24) + " days"

    const formattedHours = hours < 10 ? "0" + hours : hours
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes

    return `${formattedHours} hr : ${formattedMinutes} min`
  }

  if (loading) return <Skeleton color={color} />

  return (
    <div className="relative w-full overflow-hidden rounded-lg border-[0.5px] border-[#d3d3d3] px-6 py-4 shadow-[-1px_1px_3px_0_rgba(34,41,48,0.15)]">
      {/* {selectedTag === "Onboarding" && (
        <div className="my-[5px]">
          <Flag text="Mandatory" />
        </div>
      )} */}
      <div className="mb-2 flex justify-between font-semibold items-center">
        <div>
          {selectedTag === "Onboarding" && (
            <div className="my-[2px]">
              <Flag text="Mandatory" />
            </div>
          )}
          <p className="text-sm text-[#333]">{data[selectedTag]?.name}</p>
        </div>
        {["Progress Meet", "Class"].includes(selectedTag) && (
          <p className="text-sm text-[#DC2626]">
            {selectedTag === "Progress Meet" ? "Duration 10 Min" : timeLeftToStart > 0 && formatTime()}
          </p>
        )}
        {["Onboarding Meet", "Onboarding"].includes(selectedTag) && (
          <p className="text-sm text-[#DC2626]">{timeLeftToStart > 0 && formatTime()}</p>
        )}
      </div>
      <div>
        <p className="text-xs font-normal">
          {selectedTag === "Progress Meet" ? "Student Success Manager" : data[selectedTag]?.instructor}
        </p>
        {selectedTag === "Onboarding" && (
          <p className="text-xs font-normal w-[313px]">
            This meet will help you to get a better understanding of our portal and its features.
          </p>
        )}
        {selectedTag === "Onboarding Meet" && (
          <p className="text-xs font-normal w-[313px]">{data[selectedTag]?.manager_name}</p>
        )}
        {selectedTag !== "Onboarding" && (
          <p className="text-xs font-normal">
            {selectedTag === "Progress Meet"
              ? `${(formattedDate as { original: string })?.original} - ${(formattedDate as { oneWeekLater: string })?.oneWeekLater}`
              : `${formattedDate} ${selectedTag === "Exam" || selectedTag === "Placement Cohort" ? "" : "Onwards"} `}{" "}
          </p>
        )}
      </div>
      {selectedTag === "Progress Meet" && (
        <p className="text-xs font-medium text-[#2C4C9F]">
          You will get a Call from your assigned SSM in the given date range.
        </p>
      )}
      {selectedTag !== "Progress Meet" && (
        <div className="flex justify-end">
          <Tooltip
            title={
              (selectedTag === "Onboarding Meet" || selectedTag === "Onboarding") && timeLeftToStart > 900
                ? "Link will be active 15 mins before the onboarding meet"
                : selectedTag === "Class" && timeLeftToStart > 1800
                  ? "Link will be active half an hour before the class starts"
                  : "Join now"
            }
          >
            <div>
              <Button
                variant={"primary"}
                size={"md"}
                disabled={
                  (selectedTag === "Onboarding Meet" || selectedTag === "Onboarding") && timeLeftToStart > 900
                    ? true
                    : selectedTag === "Class" && timeLeftToStart > 1800
                      ? true
                      : selectedTag === "Assignment"
                        ? !!data[selectedTag]?.done_all_questions
                        : selectedTag === "Class" && remainingTime <= 0
                }
                className="relative z-20"
                onClick={() => {
                  handleRemainderCardClick(
                    selectedTag,
                    data[selectedTag]?.id || data[selectedTag]?.room_id,
                    data[selectedTag]?.class_link || data[selectedTag]?.student_link
                  )
                }}
              >
                {selectedTag === "Assignment" ? "Start Now" : "Join Now"}
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="absolute top-0 left-[126px]">
        <HomePageGradient className={color} />
      </div>
      <div className="absolute top-0 left-[201px]">
        <HomePageGradient className={color} />
      </div>
      <div className="absolute top-0 left-[821px] -z-10">
        <HomePageGradient className={color} />
      </div>
    </div>
  )
}

export default RemainderCard
