import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { PlacementBatchAPIDataSourceImpl } from "data/API/Admin/PlacementBatchAPIDataSourceImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { PlacementBatchRepositoryImpl } from "data/repository/Admin/PlacementBatchRepositoryImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreatePlacementBatches } from "domain/useCase/Admin/Placement/CreatePlacementBatch"
import GetAllContestsList from "domain/useCase/User/Contest/GetAllContestsList"
import useContestViewModel from "presentation/Admin/Contest/ContestViewModel"
import React from "react"

export default function CreateBatchViewModel() {
  const [_placementBatchName, _setPlacementBatchName] = React.useState("")
  const [_bridgeBatchName, _setBridgeBatchName] = React.useState("")
  const [selectBatch, setSelectBatch] = React.useState<any>([])
  const [_currentSelectBatch, _setCurrentSelectBatch] = React.useState<any>([])
  const [allContestsList, setAllContestsList] = React.useState<any>([])
  const [_currentSelectContest, _setCurrentSelectContest] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false)
  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { handleUpdatePlacementCohortBatch } = useContestViewModel() //studentplacementdashboard

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const createBatchUseCase = new CreatePlacementBatches(
    new PlacementBatchRepositoryImpl(new PlacementBatchAPIDataSourceImpl())
  )
  const getAllContestsUseCase = new GetAllContestsList(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  function handlePlacementCourseName(e: any) {
    _setPlacementBatchName(e.target.value)
  }

  function handleBridgeCourseName(e: any) {
    _setBridgeBatchName(e.target.value)
  }

  function handleBatchChange(e: any) {
    const selectedBatch = e.target.value
    if (!_currentSelectBatch.includes(selectedBatch)) {
      _setCurrentSelectBatch((prevSelectBatch: any) => [...prevSelectBatch, selectedBatch])
    }
  }

  // function handleContestChange(e: any) {
  //   const selectedContest = e.target.value
  //   if (!_currentSelectContest.includes(selectedContest)) {
  //     _setCurrentSelectContest((prevSelectContest: any) => [...prevSelectContest, selectedContest])
  //   }
  // }

  function handleContestChange(e: any) {
    const selectedContest = allContestsList.find(
      (contest: { id: string; name: string }) => contest.name === e.target.value
    )
    if (
      selectedContest &&
      !_currentSelectContest.some((contest: { id: string }) => contest.id === selectedContest.id)
    ) {
      _setCurrentSelectContest((prevSelectContest: any) => [...prevSelectContest, selectedContest])
    }
  }

  async function fetchAllContests() {
    const allContestsListResponse = await getAllContestsUseCase.invoke(auth)
    if (!allContestsListResponse?.success) {
      return
    }
    setAllContestsList(allContestsListResponse?.data)
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setSelectBatch(response.data)
    }
  }

  // function removeHyphens(inputString: any) {
  //   return inputString.replace(/-/g, "")
  // }

  async function createBatches() {
    setLoading(true)
    const response = await createBatchUseCase.invoke({
      id_token: auth.id_token.trim(),
      cohort_batch_name: _placementBatchName.trim(),
      bridge_batch_name: _bridgeBatchName.trim(),
      batches: _currentSelectBatch,
      contests: _currentSelectContest,
      test_passed_list: [],
      mock_passed_list: [],
      is_attended_list: [],
      created_on: Date.now(),
      // start_date: removeHyphens(_batchStartDate),
    })

    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.response?.data?.error)
      return
    }

    if (response?.success) {
      try {
        await Promise.all(
          _currentSelectContest.map((contest: any) =>
            handleUpdatePlacementCohortBatch(contest, _placementBatchName.trim(), _bridgeBatchName.trim(), Date.now())
          )
        )

        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Batch created successfully!")
      } catch (error) {
        console.error("Error updating placement cohort batches:", error)
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Error occurred while updating placement cohort batches!")
      }
    }
  }

  return {
    selectBatch,
    _currentSelectBatch,
    _currentSelectContest,
    allContestsList,
    _placementBatchName,
    _bridgeBatchName,
    toast,
    loading,
    refreshed,
    handleContestChange,
    handlePlacementCourseName,
    handleBridgeCourseName,
    _setCurrentSelectBatch,
    _setCurrentSelectContest,
    handleBatchChange,
    changeToastVisibility,
    getBatches,
    fetchAllContests,
    createBatches,
  }
}
