import { STR_FAILURE, STR_PAID, STR_SUCCESS } from "core/constants/strings"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import React from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { PlacementAPIDataSourceImpl } from "data/API/Student/PlacementAPIDataSourceImpl"
import { PlacementRepositoryImpl } from "data/repository/Student/PlacementRepositoryImpl"
import { format } from "date-fns"
import { ApplyJobs } from "domain/useCase/Student/Placement/ApplyForJob"
import { GetJobs } from "domain/useCase/Student/Placement/GetAllJobsList"
import { ApplicationStatus } from "domain/useCase/Student/Placement/GetApplicationStatus"
import { JobDetails } from "domain/useCase/Student/Placement/GetJobsDetails"
import { GetNewPlacement } from "domain/useCase/Student/Placement/GetNewPlacement"
import { GetPlacements } from "domain/useCase/Student/Placement/GetRecenetPlacements"
import { GetStudentJobs } from "domain/useCase/Student/Placement/GetStudentsJobs"

export default function PlacementViewModel() {
  const { auth } = useAuth()
  const { student, homeDetails } = useApp()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [placementDetailsLocally, setPlacementDetailsLocally] = useLocalStorage<any>("recent_placements", [])

  const [allPlacementsList, seAllPlacementList] = React.useState<any>(() => placementDetailsLocally)
  const [allJobsListLocally, seAllJobsListLocally] = useLocalStorage<any>("all_jobs", [])
  const [jobsList, setJobsList] = React.useState<any>(() => allJobsListLocally)
  const [tabs1Data, setTab1Data] = React.useState<any>(() => allJobsListLocally)
  const [tabs2Data, setTab2Data] = React.useState<any>({
    header: [],
    rows: [],
  })
  const [applicationStatus, setApplicationStatus] = React.useState<any>({})
  const [jobDetails, setJobsDetails] = React.useState<any>([])
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [jobStatusError, setJobStatusError] = React.useState("")
  const [isPremiumPopupVisible, setIsPremiumPopupVisible] = React.useState(false)
  const [showDescription, setShowDescription] = React.useState(-1)
  const [hideJobs] = React.useState(
    () =>
      student?.batch === "ALL" ||
      Date.now() - parseInt(homeDetails?.batch_start_timestamp) * 1000 <= 30 * 24 * 60 * 60 * 1000 // 30 days
  )
  //  ------------------------------
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const studentFourMonth =
    student?.batch_v4[0]["timestamp_start"] <= Math.floor((Date.now() - 120 * 24 * 60 * 60 * 1000) / 1000)
  const showPlacementPopup = student?.placement_batch_created >= Date.now() - 3 * 24 * 60 * 60 * 1000

  const [newPlacementList, setNewPlacementList] = React.useState<any>([])
  const getPlacementUseCase = new GetPlacements(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))

  const getNewPlacementUseCase = new GetNewPlacement(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))

  const getAllJobsUseCase = new GetJobs(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))

  const getJobDetailsUseCase = new JobDetails(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))

  const getApplicationStatusUseCase = new ApplicationStatus(
    new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl())
  )

  const getStudentJobsDetailsUseCase = new GetStudentJobs(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))

  const applyForJobsUseCase = new ApplyJobs(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))

  // React.useEffect(()=>{
  //   const timer =  setInterval(()=>{

  //        setCarouselIndex((carouselIndex+1)%4)
  //     },3000)

  //     return ()=>{
  //       clearInterval(timer);
  //     }
  //   },[carouselIndex])

  async function getRecentPlacements() {
    const response = await getPlacementUseCase.invoke(auth)
    if (response?.success) {
      seAllPlacementList(response?.data)
      setPlacementDetailsLocally(response?.data)
    }
  }

  async function getNewPlacement() {
    const response = await getNewPlacementUseCase.invoke(auth)
    if (response?.success) {
      setNewPlacementList(response?.data)
    }
  }
  async function getAllJobsList() {
    const response = await getAllJobsUseCase.invoke(auth)
    if (response?.success) {
      setJobsList(response?.data.job_list)
      setTab1Data(response?.data.job_list)
      seAllJobsListLocally(response?.data.job_list)
    }
  }

  async function getJobDetails(job_id: string, index: number) {
    setShowDescription(index)
    const response = await getJobDetailsUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
    })

    if (response?.success) {
      setJobsDetails({ ...jobDetails, [index]: response?.data })
      setShowDescription(-1)
    }
    setShowDescription(-1)
  }

  async function getStudentJobs() {
    const response = await getStudentJobsDetailsUseCase.invoke(auth)
    if (response?.success) {
      setTab2Data({
        header: ["Company", "Position", "Date", "Application Status"],
        rows: response?.data?.map((item: any) => ({
          company: item.company_name,
          role: item.role,
          date: format(new Date(item.timestamp * 1000), "dd/MM/yyyy"),
          status: item.status,
        })),
      })
    }
  }

  async function getApplicationStatus(job_id: string, index: number) {
    const response = await getApplicationStatusUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
    })
    if (response?.success) {
      setApplicationStatus({
        ...applicationStatus,
        [index]: response?.data,
      })
    } else {
      setApplicationStatus({
        ...applicationStatus,
        [index]: response?.data,
      })
      setJobStatusError(response?.data)
    }
  }
  async function applyForJob(job_id: string, index: number) {
    const paymentStatus = JSON.parse(localStorage.getItem("auth") || "{}").payment_status

    if (paymentStatus !== STR_PAID) {
      setIsPremiumPopupVisible(true)
      return
    }

    const response = await applyForJobsUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
    })

    if (response?.success) {
      setApplicationStatus(response?.data)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response?.data)
      getAllJobsList()
      getStudentJobs()
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
    }
  }

  function changeActiveButtonType(index: number) {
    setActiveButtonType(index)
  }

  function handleClosePopup() {
    setIsPremiumPopupVisible(false)
  }

  return {
    toast,
    allPlacementsList,
    newPlacementList,
    jobsList,
    jobDetails,
    tabs1Data,
    tabs2Data,
    jobStatusError,
    isPremiumPopupVisible,
    applicationStatus,
    activeButtonType,
    showDescription,
    hideJobs,
    student,
    scrollPosition,
    containerRef,
    studentFourMonth,

    getRecentPlacements,
    getNewPlacement,
    getApplicationStatus,
    getAllJobsList,
    getJobDetails,
    getStudentJobs,
    changeActiveButtonType,
    applyForJob,
    changeToastVisibility,
    changeToastDetails,
    handleClosePopup,
    showPlacementPopup,
  }
}
