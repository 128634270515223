// studentplacementdashboard
import {
  API_ADD_COHORT_STUDENT_TO_LIST,
  API_ADD_ISCOHORT_FIELD,
  API_CREATE_PLACEMENT_BATCH,
  API_V3_ADMIN,
  STUDENT_DASHBOARD,
} from "core/constants/strings"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import PlacementBatchDataSource from "data/dataSource/Admin/PlacementBatchDataSource"
import { Auth } from "domain/model/Auth"
import { PlacementBatch } from "domain/model/PlacementBatch"

const server = new Server()

export class PlacementBatchAPIDataSourceImpl implements PlacementBatchDataSource {
  async createPlacementBatch(createBatch: PlacementBatch): Promise<any> {
    try {
      const response = await server.post(pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_CREATE_PLACEMENT_BATCH), createBatch, {
        Authorization: `Bearer ${createBatch.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async addCohortStudent(
    auth: Auth,
    contestId: string,
    placementBatchName: string,
    bridgeBatchName: string,
    created_on: number
  ): Promise<any> {
    try {
      const response = await server.put(
        pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_ADD_COHORT_STUDENT_TO_LIST),
        {
          contestId: contestId,
          placementBatchName: placementBatchName,
          bridgeBatchName: bridgeBatchName,
          created_on: created_on,
        }, //tobechanged
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async addIsCohortDbFieldStored(auth: Auth, contestId: string): Promise<any> {
    try {
      // const response = await server.put(
      //   pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_ADD_ISCOHORT_FIELD),
      //   { contestId: contestId }, // tobechanged
      //   {
      //     Authorization: `Bearer ${auth.id_token}`,
      //   }
      // )
      return true
    } catch (error) {
      return error
    }
  }
}
