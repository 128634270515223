import axios from "axios"
import { FLASK_DEV, FLASK_PROD, FLASK_STAG, NODE_DEV, NODE_PROD, NODE_STAG } from "core/constants/strings"

const FLASK_SERVERS = [FLASK_PROD, FLASK_STAG, FLASK_DEV]
const NODE_SERVERS = [NODE_PROD, NODE_STAG, NODE_DEV]

export const flask_api = axios.create({ baseURL: FLASK_SERVERS[0] })
export const node_api = axios.create({ baseURL: NODE_SERVERS[0] })

flask_api.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
)
node_api.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
)

const getAPI = (route: string) => {
  return flask_api.get(route)
}
const deleteAPI = (route: string, data: any) => {
  return flask_api.delete(route, { data: data })
}
const putAPI = (route: string, data: any) => {
  return flask_api.put(route, data)
}
const postAPI = (route: string, data: any, headers?: any) => {
  return flask_api.post(route, JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...headers,
    },
  })
}
const postAPIForFiles = (route: string, data: any) => {
  return flask_api.post(route, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

// Node server class
class Server {
  get(route: string, headers?: any) {
    return node_api.get(route, { headers })
  }
  put(route: string, data: any, headers?: any) {
    return node_api.put(route, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...headers,
      },
    })
  }
  delete(route: string, data: any) {
    return node_api.delete(route, { data: data })
  }
  post(route: string, data: any, headers?: any) {
    return node_api.post(route, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...headers,
      },
    })
  }
  postFile(route: string, data: any, headers?: any) {
    return node_api.post(route, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...headers,
      },
    })
  }
}

export { deleteAPI, getAPI, postAPI, postAPIForFiles, putAPI, Server }
