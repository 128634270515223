import { Auth } from "domain/model/Auth"
import { PlacementBatchRepository } from "domain/repository/Admin/PlacementBatchRepository"

export interface AddIsCohortDbFieldStoredUseCase {
  invoke(auth: Auth, contestId: string): Promise<any>
}

export class AddIsCohortDbFieldStored implements AddIsCohortDbFieldStoredUseCase {
  private repository: PlacementBatchRepository

  constructor(repository: PlacementBatchRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contestId: string) {
    return await this.repository.addIsCohortDbFieldStored(auth, contestId)
  }
}
