import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { GetStudentList } from "domain/useCase/Admin/Placement/GetStudentList"
import GetAllContestsList from "domain/useCase/User/Contest/GetAllContestsList"
import GetPlacementContestsList from "domain/useCase/User/Contest/GetPlacementContestList"
import { useState } from "react"

export default function DashboardViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [filteredPlacementList, setFilteredPlacementList] = useState<any>([])
  const { auth } = useAuth()
  const [searchPlacement, setSearchPlacement] = useState<string>("")
  const [allContestsList, setAllContestsList] = useState<any>([])
  const [allPlacementContests, setAllPlacementContests] = useState<any>([])
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0)
  const [filters, setFilters] = useState({
    currentTab: "All Students",
    queryCohortPlacementTest: "",
    queryEmail: "",
    querySkills: [],
    queryBatch: "",
    queryProgram: "",
    queryYoe: 0,
    queryRating: 0,
    queryNoticePeriod: 0,
    queryStatus: "",
    pageIndex: 1,
    totalPage: 0,
  })

  const GetStudentListUsecase = new GetStudentList(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  const getAllContestsUseCase = new GetAllContestsList(new ContestRepositoryImpl(new ContestAPIDataSourceImpl())) //to get contest name
  const getPlacementContestsUseCase = new GetPlacementContestsList(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  async function getStudentList() {
    const response = await GetStudentListUsecase.invoke(auth, {
      queryTab: filters.currentTab,
      queryCohortPlacementTest: filters.queryCohortPlacementTest,
      queryEmail: filters.queryEmail,
      querySkills: filters.querySkills,
      queryBatch: filters.queryBatch,
      queryProgram: filters.queryProgram,
      queryYoe: filters.queryYoe,
      queryRating: filters.queryRating,
      queryNoticePeriod: filters.queryNoticePeriod,
      queryStatus: filters.queryStatus,
      pageIndex: filters.pageIndex,
    })
    if (response?.success) {
      setTotalPagesCount(response?.data?.totalPagesCount)
      setFilteredPlacementList(response?.data?.data)
    }
    console.log("DashboardViewMOdel->", response?.data?.data)
  }

  const handelFiltersChange = (value: string | number, key: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  async function fetchAllContests() {
    const allContestsListResponse = await getAllContestsUseCase.invoke(auth)
    if (!allContestsListResponse?.success) {
      return
    }
    setAllContestsList(allContestsListResponse?.data)
  }

  async function fetchAllPlacementContests() {
    const PlacementContestsListResponse = await getPlacementContestsUseCase.invoke(auth)
    if (!PlacementContestsListResponse?.success) {
      return
    }
    setAllPlacementContests(PlacementContestsListResponse?.data)
  }

  const handleChangePageNumber = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPagesCount) return
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageIndex: pageNumber,
    }))
  }

  const handleSendEmail = () => {
    console.log("Send email button Clicked")
  }

  return {
    getStudentList,
    toast,
    searchPlacement,
    filteredPlacementList,
    changeToastDetails,
    changeToastVisibility,
    filters,
    setFilters,
    handelFiltersChange,
    fetchAllContests,
    allContestsList,
    allPlacementContests,
    handleSendEmail,
    totalPagesCount,
    handleChangePageNumber,
    fetchAllPlacementContests,
    //
  }
}
