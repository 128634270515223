import { useRef, useState } from "react"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import { AIFeedbackRepositoryImpl } from "data/repository/Admin/AIFeedbackRepositoryImpl"
import { AIFeedbackAPIDataSourceImpl } from "data/API/Admin/AIFeedbackAPIDataSourceImpl"
import { GetFeedbackByEmail } from "domain/useCase/Admin/AIFeedback/GetFeedbackByEmail"
import { GetTop100Feedbacks } from "domain/useCase/Admin/AIFeedback/GetTop100Feedbacks"

export default function AIFeedbackViewModal() {
  const tableHeaders = ["General Info", "Batch", "View Question", "View Submission", "AI Feedback", "Student Feedback"]
  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [nextStudentsLoading, setNextStudentsLoading] = useState(false)
  const [studentFeedbacks, setStudentFeedbacks] = useState([])
  const [searchByEmail, setSearchByEmail] = useState<string>("")
  const [pageNumber, setPagenumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState<any>()

  const getTop100FeedbacksUseCase = new GetTop100Feedbacks(
    new AIFeedbackRepositoryImpl(new AIFeedbackAPIDataSourceImpl())
  )
  const getFeedbackByEmailUseCase = new GetFeedbackByEmail(
    new AIFeedbackRepositoryImpl(new AIFeedbackAPIDataSourceImpl())
  )

  async function getTop100Feedbacks(pageNumber: any) {
    setNextStudentsLoading(true)
    const response = await getTop100FeedbacksUseCase.invoke(auth, pageNumber)
    const temp: any = []
    for (var a in response.data) {
      let email = response.data[a].email
      let perticularStudentData = response.data[a].data
      for (var b in perticularStudentData) {
        let perticularQuestionData = perticularStudentData[b]
        for (var c in perticularQuestionData) {
          let perticularFeedbackData = perticularQuestionData[c]
          temp.push({ ...perticularFeedbackData, email, qid: b })
        }
      }
    }
    temp.sort((a: any, b: any) => new Date(a.timestamp as string).getTime() - new Date(b.timestamp as string).getTime())
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setNextStudentsLoading(false)
      return
    }
    if (response?.success) {
      setStudentFeedbacks(response?.data?.data)
    }
    setNextStudentsLoading(false)
    setNumberOfPages(
      response?.data?.length % 10 === 0 ? response?.data?.length / 10 : Math.floor(response?.data?.length / 10) + 1
    )
  }

  const getFeedbackByEmail = async (email: string) => {
    setNextStudentsLoading(true)
    const response = await getFeedbackByEmailUseCase.invoke(auth, { email })
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setNextStudentsLoading(false)
      return
    }
    if (response?.data) {
      setStudentFeedbacks(response?.data)
    } else {
      setStudentFeedbacks([])
    }
    setNextStudentsLoading(false)
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) {
      setSearchByEmail(e.target.value)
    } else setSearchByEmail("")
  }

  function handleCopy(text: string, msg: string) {
    navigator.clipboard.writeText(text)
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, `${msg} copied to clipboard`)
  }

  return {
    tableHeaders,
    nextStudentsLoading,
    handleSearchByEmail,
    searchByEmail,
    toast,
    changeToastVisibility,
    changeToastDetails,
    refreshed,
    emailInputRef,
    studentFeedbacks,
    getTop100Feedbacks,
    getFeedbackByEmail,
    handleCopy,
    pageNumber,
    setPagenumber,
    numberOfPages,
    setNumberOfPages,
  }
}
